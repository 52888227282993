import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import moment from "moment/moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import swal from "sweetalert";
import avtarPic from "../../../assets/images/Profile-Picture.jpg";
import avtarP from "../../../assets/images/Profile-Picture.jpg";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import editPro from "../../../assets/images/EditPro.svg";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PersonIcon from "@mui/icons-material/Person";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import Switch from "@mui/material/Switch";
import UserBottomForPersonalView from "../UserBottom/UserBottomForPersonalView";

import ApartmentIcon from "@mui/icons-material/Apartment";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CloseIcon from "@mui/icons-material/Close";
import SettingsVoiceOutlinedIcon from "@mui/icons-material/SettingsVoiceOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

const label = { inputProps: { "aria-label": "Switch demo" } };

function Profile() {
  const navigate = useNavigate();

  function openMick() {
    var element = document.getElementById("openMickId");
    element.classList.toggle("openMickDiv");
  }

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <ArrowBackIosNewIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Profile</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}
        <h1 class="dayDateTimeTx">Monday, 08/20/2024, 05:00 PM</h1>
      </div>

      <div className="sharedWithScreenMain">
        <div className="proDivTop">
          <div className="proDivOuter">
            <div className="proDivIn">
              <img src={avtarP} />
            </div>
            <img src={editPro} className="ediPro" />
          </div>
          <h1>John Doe</h1>
          <p>50028</p>
        </div>

        <div className="wrapper">
          <div className="taskCountMain">
            <div className="taskCountIn">
              <h1>
                <BusinessCenterIcon /> 10
              </h1>
              <p>Business Tasks</p>
            </div>
            <div className="taskCountIn">
              <h1>
                <PersonIcon /> 4
              </h1>
              <p>Personal Tasks</p>
            </div>
          </div>

          <div className="myProfileAcoMain">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Personal Active Lists
              </AccordionSummary>
              <AccordionDetails>
                <div className="proContentAcordinOut">
                  <div className="proContentAcordinInr">
                    <div className="proCont">
                      <input type="text" value="Secondary Business" />{" "}
                      <EditOutlinedIcon
                        style={{ fontSize: 21 }}
                        onClick={openMick}
                      />
                    </div>
                    <div className="proCont">
                      <p>5 Tasks</p>
                    </div>
                    <div className="proContAct">
                      <div className="switchAccount">
                        <Switch {...label} defaultChecked />
                      </div>
                      <div className="proContShar">
                        3 <ShareOutlinedIcon />
                      </div>
                      <div className="proContDele">
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  </div>

                  <div className="tabMickMain" id="openMickId">
                    <CloseIcon className="tabMickClo" onClick={openMick} />
                    <div className="tabMickMainInpOut">
                      <div className="tabMickMainInp">
                        <input
                          type="text"
                          placeholder="Type Or Speak To Create New List"
                        />
                        <SettingsVoiceOutlinedIcon />
                      </div>
                      <div className="bagNSwitch">
                        <div className="bagNSwitchInr switchAccount">
                          <Switch {...label} defaultChecked />
                          <ShoppingBagOutlinedIcon style={{ color: "#333" }} />
                        </div>
                        Enable Shopping
                      </div>
                    </div>
                    <div className="repeatMain">
                      <h3>
                        <ShoppingBagOutlinedIcon /> Repeat
                      </h3>
                      <div className="repeatOt">
                        <Button>None</Button>
                        <Button>Daily</Button>
                        <Button className="activeRepeat">Weekly</Button>
                        <Button>Monthly</Button>
                        <Button>Yearly</Button>
                      </div>
                    </div>
                    <Button className="addVoicPointBt" onClick={openMick}>
                      Add
                    </Button>
                  </div>

                  <div className="proContentAcordinInr">
                    <div className="proCont">
                      <input type="text" value="Grocery" />{" "}
                      <EditOutlinedIcon style={{ fontSize: 21 }} />
                    </div>
                    <div className="proCont">
                      <p>5 Tasks</p>
                    </div>
                    <div className="proContAct">
                      <div className="switchAccount">
                        <Switch {...label} defaultChecked />
                      </div>
                      <div className="proContShar">
                        4 <ShareOutlinedIcon />
                      </div>
                      <div className="proContDele">
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  </div>

                  <div className="proContentAcordinInr">
                    <div className="proCont">
                      <input type="text" value="Morning ToDo" />{" "}
                      <EditOutlinedIcon style={{ fontSize: 21 }} />
                    </div>
                    <div className="proCont">
                      <p>5 Tasks</p>
                    </div>
                    <div className="proContAct">
                      <div className="switchAccount">
                        <Switch {...label} defaultChecked />
                      </div>
                      <div className="proContShar">
                        3 <ShareOutlinedIcon />
                      </div>
                      <div className="proContDele">
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Archived List
              </AccordionSummary>
              <AccordionDetails>
                <div className="proContentAcordinOut">
                  <div className="proContentAcordinInr">
                    <div className="proCont">
                      <input type="text" value="Secondary Business" />{" "}
                      <EditOutlinedIcon style={{ fontSize: 21 }} />
                    </div>
                    <div className="proCont">
                      <p>5 Tasks</p>
                    </div>
                    <div className="proContAct">
                      <div className="switchAccount">
                        <Switch {...label} defaultChecked />
                      </div>
                      <div className="proContShar">
                        3 <ShareOutlinedIcon />
                      </div>
                      <div className="proContDele">
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  </div>

                  <div className="proContentAcordinInr">
                    <div className="proCont">
                      <input type="text" value="Grocery" />{" "}
                      <EditOutlinedIcon style={{ fontSize: 21 }} />
                    </div>
                    <div className="proCont">
                      <p>5 Tasks</p>
                    </div>
                    <div className="proContAct">
                      <div className="switchAccount">
                        <Switch {...label} defaultChecked />
                      </div>
                      <div className="proContShar">
                        4 <ShareOutlinedIcon />
                      </div>
                      <div className="proContDele">
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  </div>

                  <div className="proContentAcordinInr">
                    <div className="proCont">
                      <input type="text" value="Morning ToDo" />{" "}
                      <EditOutlinedIcon style={{ fontSize: 21 }} />
                    </div>
                    <div className="proCont">
                      <p>5 Tasks</p>
                    </div>
                    <div className="proContAct">
                      <div className="switchAccount">
                        <Switch {...label} defaultChecked />
                      </div>
                      <div className="proContShar">
                        3 <ShareOutlinedIcon />
                      </div>
                      <div className="proContDele">
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="profileSwitchOuter">
          {/* <UserBottomForPersonalView /> */}
          <div className="profileSwitchMain">
            <div className="profileSwitchInr">
              <Button onClick={() => navigate("/ReportAdmin")}>
                <ApartmentIcon /> Business
              </Button>
              <Button
                className="activeProSwich"
                onClick={() => navigate("/PersonalTaskNew")}
              >
                <PermIdentityIcon /> Personal
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
