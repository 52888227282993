import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PieChartIcon from "@mui/icons-material/PieChart";
import { useLocation, useNavigate } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

function SuperAdminBottomTab() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState("recents");

  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/SuperAdminDashboard");
        break;
      case 1:
        navigate("/AdminList");
        break;
      default:
        setValue(0);
    }
  };

  React.useEffect(() => {
    switch (location.pathname) {
      case "/SuperAdminDashboard":
        setValue(0);
        break;
      case "/AdminList":
        setValue(1);
        break;
      default:
        setValue(0);
    }
  }, []);

  return (
    <>
      <div className="adminBottomNavMain">
        <BottomNavigation value={value} onChange={handleChange}>
          <BottomNavigationAction
            label=""
            value={0}
            icon={<PieChartIcon />}
            //onClick={() => navigate("/SuperAdminDashboard")}
          />
          <BottomNavigationAction
            label=""
            value={1}
            icon={<PersonAddIcon />}
            //onClick={() => navigate("/AdminList")}
          />
          <BottomNavigationAction
            onClick={logoutFunction}
            label=""
            value={2}
            icon={<PowerSettingsNewIcon />}
            //onClick={() => navigate("/AdminList")}
          />
        </BottomNavigation>
      </div>
      <div className="adminBottomNavPatch"></div>
    </>
  );
}

export default SuperAdminBottomTab;
