import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function TextError(props) {
  return (
    <div className="errorSt">
      <ErrorOutlineIcon /> {props.children}
    </div>
  );
}

export default TextError;
