import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Editor } from "primereact/editor";
import VerifiedIcon from "@mui/icons-material/Verified";

import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import TextError from "../../TextError";



function AddCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [frequencyErrorMsg, setFrequencyErrorMsg] = React.useState("");
  const [frequancyList, setFrequancyList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [checkboxOptions, setcheckboxOptions] = React.useState([]);
  const [formValues, setFormValues] = React.useState(null);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryname, setCategoryname] = React.useState("");

  const initialValues = {
    category_name: categoryname,
    frequancy_ids: selectedOptions,
  };

  const validationSchema = Yup.object({
    category_name: Yup.string().required("Required"),
    //frequancy_ids: Yup.array().min(1, "1 Checked Field is Required").required("Required"),
  });

  const handleCategoryName = (e) => {
    if (e.target.value != '') {
      setCategoryname(e.target.value)

    }
    
  };
  
  const editCategory = (data) => {
    console.log("Call", data);
    setIsEditCategory(true);
    if(data) {
      setFormValues(data);
      if (data.frequancy_ids != null) {
        setSelectedOptions(data.frequancy_ids);
      } else {
        setSelectedOptions([]);
      }
      
      const saveValues = {
        categoryid: data.categoryid,
        category_name: data.category_name,
        frequancy_ids: data.frequancy_ids
      };
      setFormValues(saveValues);
    } else setFormValues(null);
  }

  

  const handleCheckboxChange = (event, name) => {
    console.log("Checkbox", event.target.value);
    const value = event.target.value;
    const checked = event.target.checked;
    
    if (checked) {
      
      if (name == "Any" || name == "any") {
        setSelectedOptions([parseInt(value)])
      } else {
        var removeValue;
        var result = checkboxOptions.map(function (str) {
          if(str.value == "Any" || str.value == "any")
            removeValue = parseInt(str.key)
          return removeValue; 
        });
        console.log("Result", removeValue)
        const index = selectedOptions.indexOf(removeValue);
          if (index > -1) { // only splice array when item is found
            selectedOptions.splice(index, 1); // 2nd parameter means remove one item only
          }
        //setSelectedOptions(selectedOptions.filter(e => e == parseInt(removeValue)))
        setSelectedOptions([...selectedOptions, parseInt(value)])
      }
    } else {
      if (selectedOptions !== "") {
        setSelectedOptions(selectedOptions.filter(e => e !== parseInt(value)))
      } else setSelectedOptions([]);
    }
    
    /* setSelectedOptions((prev) => 
      prev.includes(value) 
        ? prev.filter(option => option !== value) 
        : [...prev, value]
    ); */
    console.log("selectedOptions", selectedOptions);
    
  };

  const onSubmit = async (values) => {
    console.log("Form Data", values);
    if (selectedOptions.length > 0 || selectedOptions == null) {
      
    var result = values.frequancy_ids.map(function(str) {
      return parseInt(str);
    });
    console.log("FreqData", result);
    console.log(values.frequancy_ids);
    if (isEditCategory) {
      setShowLoader(true);
    setLoginErrMsg("");
      

    var result = values.frequancy_ids.map(function(str) {
      return parseInt(str);
    });
       
    values.frequancy_ids = selectedOptions;
    values.status = true;
    values.is_deleted = false;

    await makePostRequest("category/updateCategory", values)
      .then((response) => {
        console.log("error with response===>"+response);
        if (response.data) {
          console.log(response)
          setLoginErrMsg("");
          setShowLoader(false);
          setFormValues(null);
          localStorage.setItem("TabName", "Category")
          navigate('/AdminTasksList');
        }
      })
      .catch((err) => {
        swal("Warning", err.response.data.message, "warning", {
          timer: 3000,
          buttons: false,
        });
        setShowLoader(false);
      });
    } else {
    setShowLoader(true);
    setLoginErrMsg("");
    
    values.frequancy_ids = selectedOptions;
    values.status = true;
    values.is_deleted = false;

    await makePostRequest("category/insertcategory", values)
      .then((response) => {
        console.log("error with response===>"+response);
        if (response.data) {
          console.log(response)
          setLoginErrMsg("");
          setShowLoader(false);
          setFormValues(null);
          localStorage.setItem("TabName", "Category")
          navigate('/AdminTasksList');
        }
      })
      .catch((err) => {
        swal("Warning", err.response.data.message, "warning", {
          timer: 3000,
          buttons: false,
        });
        setShowLoader(false);
        
      });
      }
    } else {
      setFrequencyErrorMsg("Select at least one option")
    }
  }

  async function getfrequancylist(searchString) {
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "frequancy/getfrequancylist/frequancy_name/asc/null/" )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            console.log("drop res",response.data.data.res);
            var x = response.data.data.res.map(function (obj) {
              
              var myKey = Object.values(obj)[0]
              var myValue = Object.values(obj)[1]
              return {key: myKey, value: myValue}
             })

             
             let anyvalues = {key:0, value:'Any'}
             x.unshift(anyvalues)
             
             setcheckboxOptions(x)
             console.log("XXXXXXXX"+anyvalues)
            //setTotalItems(response.data.data.totalCount);
          } else setcheckboxOptions(null);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  /* const checkValue = (e) => {
    console.log(e.target.id);
    console.log(e.target.value);
    console.log(e.target.checked);
    const Checked = e.target.checked;
    const Id = e.target.id;
    if (Checked) {
      setEditcheckboxOptions([
        ...EditcheckboxOptions, Id
      ])
    } else {
      setEditcheckboxOptions(EditcheckboxOptions.filter(e => e.target.id !== e.target.id))
    }
    console.log(EditcheckboxOptions);
  } */

  React.useEffect(() => {
    getfrequancylist(null);
    localStorage.setItem("TabName", "Category")
    if(data) editCategory(data);
  }, []);

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
          </div>
          <h1>{isEditCategory?<>Edit</>: <>Add</>} Task List</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain forUpperBottomBt">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Task List Name"
                      placeholder="Task List Name"
                      labelClass="myLable"
                      name="category_name"
                      className="myInput"
                      onBlur={(e) => handleCategoryName(e)}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <FormikControl
                      control='checkbox'
                      label='Select Frequency'
                      labelClass="selectFreq"
                      name='frequancy_ids'
                      options={checkboxOptions}
                    />
                  </Grid> */}
                  {checkboxOptions != undefined ? <Grid item xs={12} md={12}>
                    <div className="categoryChecks">
                      <FormGroup>
                    {checkboxOptions.map((obj, index) => (
                      <FormControlLabel
                        name="frequancy_ids"
                        control={
                          <Checkbox
                            id={obj.key}
                            value={obj.key}
                            name="frequancy_ids"
                            checked={selectedOptions.includes(obj.key)}
                            onChange={(e)=>handleCheckboxChange(e, obj.value)}
                      
                          />
                        }
                        label={obj.value} 
                      />
                    ))}
                      </FormGroup>
                    </div>
                    <p className="errorSt"> {frequencyErrorMsg}</p>
                  </Grid>:<Grid item xs={12} md={12}><div className="blankDataMain"><ReportGmailerrorredIcon/> Please add Frequancy first!</div></Grid>}
                  <Grid item xs={12} md={12} style={{textAlign:'center'}}>
                    <div className="upperBottomBt"><Button className="logginBt frequancyBt" onClick={() => navigate("/AddFrequency")}><AddIcon/> Add Frequancy</Button></div>
                  </Grid>

                  {/* <Grid item xs={12} md={12}>
                    <h1 className="selectFreq">Select Frequency</h1>
                    <div className="freqMain">
                      <Button className="activeFreq">
                        <VerifiedIcon /> Morning Shift
                      </Button>
                      <Button>
                        <VerifiedIcon /> Afternoon Shift
                      </Button>
                      <Button>
                        <VerifiedIcon /> Evening Shift
                      </Button>
                      <Button>
                        <VerifiedIcon /> Night Shift
                      </Button>
                    </div>
                  </Grid> */}
{checkboxOptions != undefined ?
                  <Grid item xs={12} md={12}>
                    <div className="upperBottomBt">
                      <Button type="submit" className="logginBt">{isEditCategory?<>Edit</>: <>Add</>} Task List</Button>
                    </div>
                  </Grid>:<></>}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <AdminBottomNav />
      </div>
    </>
  );
}

export default AddCategory;
