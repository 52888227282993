import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Editor } from "primereact/editor";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";

function AdminEditTask() {
  const navigate = useNavigate();

  const [text, setText] = useState("");

  const initialValues = {
    add_title: "",
    procedure: "",
  };

  const validationSchema = Yup.object({
    add_title: Yup.string().required("Required"),
    procedure: Yup.string().required("Required"),
  });

  const onSubmit = (values) => console.log("Form Data", values);

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
          </div>
          <h1>Edit Task</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Add Title"
                      placeholder="Add Title"
                      labelClass="myLable"
                      name="add_title"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="myCheckBx">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Does it need Before and After photos?"
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <label className="myLable">Procedure</label>
                    <div className="card myTxEditor">
                      <Editor
                        value={text}
                        onTextChange={(e) => setText(e.htmlValue)}
                        style={{ height: "320px" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={<Switch defaultChecked />}
                        label="Active"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button className="logginBt">Add Task</Button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <h1 className="deleteEmpTx">Delete Employee</h1>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <AdminBottomNav />
      </div>
    </>
  );
}

export default AdminEditTask;
