import React from "react";
import splasIm from "../../../assets/images/Splash-man.png";
import { useNavigate } from "react-router-dom";

function SplashEmployee() {
  const navigate = useNavigate();
  return (
    <>
      <div className="splashMainDiv" onClick={() => navigate("/LoginEmployee")}>
        <img src={splasIm} />
        <h1>ToDoBoom</h1>
        <p>Stay on top of your to-do list.</p>
      </div>
    </>
  );
}

export default SplashEmployee;
