import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Editor } from "primereact/editor";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";
import { makeGetRequest } from "../../../utils/utils";

function TaskDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state || {};
  const [showLoader, setShowLoader] = React.useState(false);
  const [taskname, setTaskname] = React.useState("");
  const [taskdescription, setTaskDescription] = React.useState("");
  const [imageUpload, setImageUpload] = useState([])
  const getDetails = (Id) => {
    if (Id) {
      setShowLoader(true);
      makeGetRequest("task/gettask/" + Id)
        .then((response) => {
          if (response.data) {
            setTaskname(response.data.data[0].task_name);
            setTaskDescription(response.data.data[0].task_description);
            setImageUpload(response.data.data[0].media)
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    if (Id) getDetails(Id);
  }, []);

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>{taskname}</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <h1 className="tskHeading">Task Procedure</h1>
            </Grid>
            <Grid item xs={12} md={12}>
              <h2 className="tskDisc tskDiscDeatils">
                <div
                  dangerouslySetInnerHTML={{ __html: `${taskdescription}` }}
                />
              </h2>
              <Grid item xs={12} md={12}>
                
                <div className="uploadedImMain">
                  {imageUpload ?
                    <>
                      {imageUpload.map((uploadImg, index) => (
                    <Zoom>
                    <div className="uploadedImInr" key={index}>
                    <img src={uploadImg} style={{height:100, width:100, borderRadius:5}}/>
                    </div>
                    </Zoom>))}
                  </> : 
                  null}
                </div>
                
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <div className="tskBuletPoints">
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em>{" "}
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum is simply dummy text</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum dummy text</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em>{" "}
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum is simply dummy text</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum dummy text</span>
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>

        <AdminBottomNav />
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default TaskDetails;
