import React, { useState, useEffect } from 'react';

const VoiceToTextTask = () => {
  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [isListening, setIsListening] = useState(false);
  
  useEffect(() => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Your browser does not support speech recognition. Please try Chrome.');
      return;
    }
    
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setTask(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const handleStartListening = () => {
    setIsListening(true);
  };

  const handleAddTask = () => {
    if (task) {
      setTasks([...tasks, task]);
      setTask('');
    }
  };

  return (
    <div>
      <h1>Voice to Text Task</h1>
      <button onClick={handleStartListening} disabled={isListening}>
        Start Listening
      </button>
      <input
        type="text"
        value={task}
        readOnly
      />
      <button onClick={handleAddTask} disabled={!task}>
        Add Task
      </button>
      <ul>
        {tasks.map((t, index) => (
          <li key={index}>{t}</li>
        ))}
      </ul>
    </div>
  );
};

export default VoiceToTextTask;