import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PieChartIcon from "@mui/icons-material/PieChart";
import { useLocation, useNavigate } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

function AdminBottomNav() {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = React.useState("recents");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
          navigate("/ReportAdmin");
          break;
      case 1:
          navigate("/EmployeeListing");
          break;
      case 2:
          navigate("/AdminTasksList");
          break;
      case 3:
          navigate("/TasksScheduler");
          break;
      case 4:
          navigate("/WeeklyTasks");
          break;
      default:
          setValue(0);
  };
  };

  React.useEffect(() => {
    switch (location.pathname) {
        case "/ReportAdmin":
            setValue(0);
            break;
        case "/EmployeeListing":
            setValue(1);
            break;
        case "/AdminTasksList":
            setValue(2);
            break;
        case "/TasksScheduler":
            setValue(3);
            break;
        case "/WeeklyTasks":
            setValue(4);
            break;
        default:
            setValue(0);
    }
}, [])
  return (
    <>
      <div className="adminBottomNavMain">
        <BottomNavigation value={value} onChange={handleChange}>
          <BottomNavigationAction
            label=""
            value={0}
            icon={<PieChartIcon />}
            //onClick={() => navigate("/ReportAdmin")}
          />
          <BottomNavigationAction
            label=""
            value={1}
            icon={<PersonAddIcon />}
            //onClick={() => navigate("/EmployeeListing")}
          />
          <BottomNavigationAction
            label=""
            value={2}
            icon={<PlaylistAddIcon />}
            //onClick={() => navigate("/AdminTasksList")}
          />
          <BottomNavigationAction
            label=""
            value={3}
            icon={<EventAvailableIcon />}
           //onClick={() => navigate("/TasksScheduler")}
          />
          <BottomNavigationAction
            label=""
            value={4}
            icon={<ManageAccountsIcon />}
            //onClick={() => navigate("/WeeklyTasks")}
          />
        </BottomNavigation>
      </div>
      <div className="adminBottomNavPatch"></div>
    </>
  );
}

export default AdminBottomNav;
