import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../../admin/AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import VerifiedIcon from "@mui/icons-material/Verified";
import underContruction from "../../../assets/images/under-construction.gif";

// For Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// End

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import CloseIcon from "@mui/icons-material/Close";
import calendarIm from "../../../assets/images/calendar.png";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import Checkbox from "@mui/material/Checkbox";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";

import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import swal from "sweetalert";
import AppDrawer from "../../AppDrawer";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ENd

function CheckTasksAvailability() {
  const navigate = useNavigate();

  // State for the table rows
  const [rows, setRows] = useState([
    {
      id: 1,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
    {
      id: 2,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
    {
      id: 3,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
  ]);

  // Toggle the "select all" checkboxes in a row
  const handleSelectAllChange = (rowId) => {
    setRows(
      rows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              selectAll: !row.selectAll,
              checkboxes: row.checkboxes.map(() => !row.selectAll),
            }
          : row
      )
    );
  };

  // Toggle individual checkbox
  const handleCheckboxChange = (rowId, index) => {
    setRows(
      rows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              checkboxes: row.checkboxes.map((checked, i) =>
                i === index ? !checked : checked
              ),
              selectAll: row.checkboxes.every((checked, i) =>
                i === index ? !checked : checked
              ),
            }
          : row
      )
    );
  };

  const forExpand = () => {
    var element = document.getElementById("expandId");
    element.classList.toggle("expanded");
  };

  const forOmcSelect = () => {
    var element = document.getElementById("omcID");
    element.classList.toggle("selectedOmc");
  };

  const swipeNSelection = (event) => {
    //forSelectList(event);
    resetSwipeDirection(event);
  };

  const [clickedId, setClickedId] = useState(null);
  const handleClick = (id) => {
    setClickedId(id);
  };

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  // For Modal
  const [ShipTiming, setOpenShipTiming] = React.useState(false);

  const handleClickOpenShipTiming = () => {
    setOpenShipTiming(true);
  };

  const handleCloseShipTiming = () => {
    setOpenShipTiming(false);
  };
  // End

  /* const forSelectList = () => {
    var element = document.getElementById("listID");
    element.classList.toggle("selectedList");

    var element = document.getElementById("expandId");
    element.classList.toggle("expanded");
  }; */

  const handleChangeWeekDayCheckBox = (
    isChecked,
    index,
    taskids,
    weekDay,
    frequancyid,
    categoryid
  ) => {
    let tempList = [];
    if (weekDay == "Monday") {
      tempList = [...selectedMenuOfMonday.frequancyid];
      if (tempList.length == 0) {
        selectedMenuOfMonday.categoryid = categoryid;
        selectedMenuOfMonday.taskid = taskids;
        selectedMenuOfMonday.weekday = weekDay;
        selectedMenuOfMonday.status = true;
      }
      if (isChecked == true) {
        tempList.push(frequancyid);
        document
          .getElementById("mon" + index + "" + taskids + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(frequancyid), 1);
        document
          .getElementById("mon" + index + "" + taskids + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfMonday.frequancyid = tempList;
      console.log(
        "selectedMenuOfMonday===>" + JSON.stringify(selectedMenuOfMonday)
      );
      setUpload(!upload);
    } else if (weekDay == "Tuesday") {
      tempList = [...selectedScheduleOfTuesday.frequancyid];

      if (tempList.length == 0) {
        selectedScheduleOfTuesday.categoryid = categoryid;
        selectedScheduleOfTuesday.taskid = taskids;
        selectedScheduleOfTuesday.weekday = weekDay;
        selectedScheduleOfTuesday.status = true;
      }

      if (isChecked == true) {
        tempList.push(frequancyid);
        document
          .getElementById("tues" + index + "" + taskids + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(frequancyid), 1);
        document
          .getElementById("tues" + index + "" + taskids + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedScheduleOfTuesday.frequancyid = tempList;
      console.log(
        "selectedScheduleOfTuesday===>" +
          JSON.stringify(selectedScheduleOfTuesday)
      );
      setUpload(!upload);
    } else if (weekDay == "Wednesday") {
      tempList = [...selectedMenuOfWednesday.frequancyid];

      if (tempList.length == 0) {
        selectedMenuOfWednesday.categoryid = categoryid;
        selectedMenuOfWednesday.taskid = taskids;
        selectedMenuOfWednesday.weekday = weekDay;
        selectedMenuOfWednesday.status = true;
      }

      if (isChecked == true) {
        tempList.push(frequancyid);
        document
          .getElementById("wed" + index + "" + taskids + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(frequancyid), 1);
        document
          .getElementById("wed" + index + "" + taskids + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfWednesday.frequancyid = tempList;
      setSelectedMenuOfWednesday(selectedMenuOfWednesday);
      setUpload(!upload);
      //console.log(selectedMenuOfWednesday);
    } else if (weekDay == "Thursday") {
      tempList = [...selectedMenuOfThursday.frequancyid];

      if (tempList.length == 0) {
        selectedMenuOfThursday.categoryid = categoryid;
        selectedMenuOfThursday.taskid = taskids;
        selectedMenuOfThursday.weekday = weekDay;
        selectedMenuOfThursday.status = true;
      }

      if (isChecked == true) {
        tempList.push(frequancyid);
        document
          .getElementById("thur" + index + "" + taskids + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(frequancyid), 1);
        document
          .getElementById("thur" + index + "" + taskids + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfThursday.frequancyid = tempList;
      setSelectedMenuOfThursday(selectedMenuOfThursday);
      setUpload(!upload);
      //console.log(selectedMenuOfThursday);
    } else if (weekDay == "Friday") {
      tempList = [...selectedMenuOfFriday.frequancyid];

      if (tempList.length == 0) {
        selectedMenuOfFriday.categoryid = categoryid;
        selectedMenuOfFriday.taskid = taskids;
        selectedMenuOfFriday.weekday = weekDay;
        selectedMenuOfFriday.status = true;
      }
      if (isChecked == true) {
        tempList.push(frequancyid);
        document
          .getElementById("fri" + index + "" + taskids + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(frequancyid), 1);
        document
          .getElementById("fri" + index + "" + taskids + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfFriday.frequancyid = tempList;
      setSelectedMenuOfFriday(selectedMenuOfFriday);
      setUpload(!upload);
      //console.log(selectedMenuOfFriday);
    } else if (weekDay == "Saturday") {
      tempList = [...selectedMenuOfSaturday.frequancyid];

      if (tempList.length == 0) {
        selectedMenuOfSaturday.categoryid = categoryid;
        selectedMenuOfSaturday.taskid = taskids;
        selectedMenuOfSaturday.weekday = weekDay;
        selectedMenuOfSaturday.status = true;
      }
      if (isChecked == true) {
        tempList.push(frequancyid);
        document
          .getElementById("sat" + index + "" + taskids + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(frequancyid), 1);
        document
          .getElementById("sat" + index + "" + taskids + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfSaturday.frequancyid = tempList;
      setSelectedMenuOfSaturday(selectedMenuOfSaturday);
      setUpload(!upload);
      //console.log(selectedMenuOfSaturday);
    } else if (weekDay == "Sunday") {
      tempList = [...selectedMenuOfSunday.frequancyid];

      if (tempList.length == 0) {
        selectedMenuOfSunday.categoryid = categoryid;
        selectedMenuOfSunday.taskid = taskids;
        selectedMenuOfSunday.weekday = weekDay;
        selectedMenuOfSunday.status = true;
      }
      if (isChecked == true) {
        tempList.push(frequancyid);
        document
          .getElementById("sunday" + index + "" + taskids + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(frequancyid), 1);
        document
          .getElementById("sunday" + index + "" + taskids + "" + categoryid)
          .removeAttribute("checked".false);
      }

      selectedMenuOfSunday.frequancyid = tempList;
      setSelectedMenuOfSunday(selectedMenuOfSunday);
      console.log(selectedMenuOfSunday);
      setUpload(!upload);
    }
  };

  async function getselectedSchedular(catid, taskid) {
    //setShowLoader(true);

    setSelectedMenuOfMonday({
      schedularid: "",
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

    setSelectedMenuOfSunday({
      schedularid: "",
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

    setSelectedScheduleOfTuesday({
      schedularid: "",
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

    setSelectedMenuOfWednesday({
      schedularid: "",
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

    setSelectedMenuOfThursday({
      schedularid: "",
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

    setSelectedMenuOfFriday({
      schedularid: "",
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

    setSelectedMenuOfSaturday({
      schedularid: "",
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

    await makeGetRequest(
      "schedular/getselectedschedular/" + catid + "/" + taskid
    )
      .then((response) => {
        if (response.data.data) {
          let temp = response.data.data;

          if (temp.length > 0) {
            for (var i = 0; i < temp.length; i++) {
              console.log("ssssssssssssss" + JSON.stringify(temp[i]));
              if (temp[i].weekday == "Monday") {
                setSelectedMenuOfMonday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Tuesday") {
                setSelectedScheduleOfTuesday(temp[i]);
                setUpload(!upload);
                console.log("tempof is==>" + JSON.stringify(temp[i]));
              }
              if (temp[i].weekday == "Wednesday") {
                setSelectedMenuOfWednesday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Thursday") {
                setSelectedMenuOfThursday(temp[i]);
                setUpload(!upload);
                console.log("Thursday====>" + selectedMenuOfThursday);
              }
              if (temp[i].weekday == "Friday") {
                setSelectedMenuOfFriday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Saturday") {
                setSelectedMenuOfSaturday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Sunday") {
                setSelectedMenuOfSunday(temp[i]);
                setUpload(!upload);
              }
            }
          } else {
            console.log("ELSE EXECUTED");
            setSelectedMenuOfMonday({
              categoryid: "",
              taskid: "",
              weekday: "",
              frequancyid: [],
              status: "",
            });
            setSelectedScheduleOfTuesday({
              categoryid: "",
              taskid: "",
              weekday: "",
              frequancyid: [],
              status: "",
            });
            setSelectedMenuOfWednesday({
              categoryid: "",
              taskid: "",
              weekday: "",
              frequancyid: [],
              status: "",
            });
            setSelectedMenuOfThursday({
              categoryid: "",
              taskid: "",
              weekday: "",
              frequancyid: [],
              status: "",
            });
            setSelectedMenuOfFriday({
              categoryid: "",
              taskid: "",
              weekday: "",
              frequancyid: [],
              status: "",
            });
            setSelectedMenuOfSaturday({
              categoryid: "",
              taskid: "",
              weekday: "",
              frequancyid: [],
              status: "",
            });
            setSelectedMenuOfSunday({
              categoryid: "",
              taskid: "",
              weekday: "",
              frequancyid: [],
              status: "",
            });
          }

          console.log(
            "Selected response===>" + JSON.stringify(response.data.data)
          );

          //setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  function add(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.taskid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  async function forSelectList(e, index, id, catid) {
    setTaskID(id);
    setTempSlide(index + "" + catid + "" + id);
    let results = add(selectedTaskList, taskid);
    if (results == false) {
      var element = document.getElementById("listID-" + tempSlide);
      if (element != null) {
        element.classList.toggle("selectedList");
      }

      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null) {
        elementexp.classList.toggle("expanded");
      }
    } else if (results == true) {
      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null) {
        elementexp.classList.toggle("expanded");
      }
    } else {
      var element = document.getElementById("listID-" + tempSlide);
      if (element != null) {
        element.classList.toggle("selectedList");
      }

      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null) {
        elementexp.classList.toggle("expanded");
      }
    }
    setToggleId(index);
    setShowLoader(true);
    await getselectedSchedular(catid, id);

    var element = document.getElementById("listID-" + index + catid + id);
    element.classList.add("selectedList");

    var elementexp = document.getElementById("expandId-" + index + catid + id);
    elementexp.classList.toggle("expanded");

    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  }

  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setSearchText("");
    setValue(newValue);
    setTempSlide("");
    getAllTaskList(null);
  };
  // End

  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        setSwipeDirection("right");
      } else {
        // setSwipeDirection("left");
      }
    }
  };

  const handleTouchEnd = () => {
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  const getSelectCount = (id) => {
    setShowLoader(true);
    setCategoryID(id);

    makeGetRequest("schedular/getschedularCount/" + parseInt(id))
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.res.rows.length > 0) {
            setSelectedTaskList(response.data.data.res.rows);
            console.log(
              "my Count deam...." + JSON.stringify(response.data.data.res.rows)
            );
            setIncValue(response.data.data.totalCount);
            setShowLoader(false);
          } else {
            setSelectedTaskList();
            setIncValue(0);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
        //setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const [showLoader, setShowLoader] = React.useState(false);
  const [incvalue, setIncValue] = React.useState(0);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [tempSlide, setTempSlide] = React.useState("");
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [selected, setSelected] = React.useState(false);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [categoryid, setCategoryID] = React.useState([]);
  const [frequancyid, setFrequancyID] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [selectedTaskList, setSelectedTaskList] = React.useState();
  const [taskList, setTaskList] = React.useState([]);
  const [selectedMenuItemList, setSelectedMenuItemList] = React.useState([]);
  const [toggleId, setToggleId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedMenuOfSunday, setSelectedMenuOfSunday] = React.useState({
    categoryid: "",
    taskid: "",
    weekday: "",
    frequancyid: [],
    status: "",
  });

  const [selectedMenuOfMonday, setSelectedMenuOfMonday] = React.useState({
    categoryid: "",
    taskid: "",
    weekday: "",
    frequancyid: [],
    status: "",
  });

  const [selectedScheduleOfTuesday, setSelectedScheduleOfTuesday] =
    React.useState({
      categoryid: "",
      taskid: "",
      weekday: "",
      frequancyid: [],
      status: "",
    });

  const [selectedMenuOfWednesday, setSelectedMenuOfWednesday] = React.useState({
    categoryid: "",
    taskid: "",
    weekday: "",
    frequancyid: [],
    status: "",
  });
  const [selectedMenuOfThursday, setSelectedMenuOfThursday] = React.useState({
    categoryid: "",
    taskid: "",
    weekday: "",
    frequancyid: [],
    status: "",
  });
  const [selectedMenuOfFriday, setSelectedMenuOfFriday] = React.useState({
    categoryid: "",
    taskid: "",
    weekday: "",
    frequancyid: [],
    status: "",
  });
  const [selectedMenuOfSaturday, setSelectedMenuOfSaturday] = React.useState({
    categoryid: "",
    taskid: "",
    weekday: "",
    frequancyid: [],
    status: "",
  });

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(searchText);
  }

  const changeList = (e, id) => {
    setShowLoader(true);
    setCategoryID(id);

    makeGetRequest("schedular/getschedularCategory/" + parseInt(id))
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            //console.log("my deam...."+response.data.data.res)
            setFreequancyList(response.data.data);
            getSelectCount(parseInt(id));
          } else {
            setFreequancyList([
              {
                frequancyid: 0,
                frequancy_name: "Any",
                status: true,
                is_deleted: false,
                created_at: "2024-08-29T05:29:23.767Z",
                updated_at: "2024-09-20T12:12:10.157Z",
                categoryid: id,
              },
            ]);
            getSelectCount(parseInt(id));
          }
          setUpload(!upload);
        }
        //setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  async function updateSchedular(e, index, id, catid) {
    setShowLoader(true);
    if (selectedMenuOfMonday.frequancyid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfMonday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedScheduleOfTuesday.frequancyid.length > 0) {
      selectedMenuItemList.push(selectedScheduleOfTuesday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfWednesday.frequancyid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfWednesday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfThursday.frequancyid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfThursday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfFriday.frequancyid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfFriday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfSaturday.frequancyid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfSaturday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfSunday.frequancyid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfSunday);
      setSelectedMenuItemList(selectedMenuItemList);
    }

    console.log(selectedMenuItemList);

    await makeGetRequest(
      "schedular/deleteschedular/" + parseInt(catid) + "/" + parseInt(id)
    )
      .then((response) => {
        console.log("delete", response);
      })
      .catch((err) => {});
    if (selectedMenuItemList.length > 0) {
      console.log("sagar", JSON.stringify(selectedMenuItemList));
      for (var i = 0; i < selectedMenuItemList.length; i++) {
        //alert(selectedMenuItemList[i].frequancyid)

        /* if (selectedMenuItemList[i].schedularid) 
      {
        alert("Update", selectedMenuItemList[i].schedularid)
        console.log("Update", selectedMenuItemList[i])
        let taskObj = selectedMenuItemList[i];
        delete taskObj.schedularid;
            await makePostRequest("schedular/updatetaskschedular",taskObj).then((response) => {
              if (response.data.data) 
                {
                  setSelectedMenuItemList([])
                }
            }).catch((err) => {
              
            });
        } 
        else 
        { */
        let taskObj = selectedMenuItemList[i];
        delete taskObj.schedularid;
        console.log("Insert", taskObj);
        await makePostRequest("schedular/inserttaskschedular", taskObj)
          .then((response) => {
            if (response.data.data) {
              setSelectedMenuItemList([]);
            }
          })
          .catch((err) => {});
        //}
      }
      getSelectCount(catid);

      swal("Success", "The task has added successfully", "success", {
        timer: 3000,
        buttons: false,
      });

      setShowLoader(false);
    } else {
      setTaskID(id);
      setTempSlide(index + "" + catid + "" + id);
      //alert("Else Part"+ index+""+catid+""+id)

      await makeGetRequest(
        "schedular/deleteschedular/" + parseInt(catid) + "/" + parseInt(id)
      )
        .then((response) => {
          console.log("delete", response);

          var element = document.getElementById("listID-" + tempSlide);
          if (element != null) {
            element.classList.toggle("selectedList");
          }
          var element = document.getElementById("listPID-" + tempSlide);
          if (element != null) {
            element.classList.toggle("selectedList");
          }
          var elementexp = document.getElementById("expandId-" + tempSlide);
          if (elementexp != null) {
            elementexp.classList.toggle("expanded");
          }
          getSelectCount(parseInt(catid));
          setTaskID(0);
          setTempSlide("");
        })
        .catch((err) => {});
      setShowLoader(false);
    }
  }

  async function getAllTaskList(searchString) {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    setShowLoader(true);
    await makeGetRequest(
      "task/gettasklist/" + sortColumnName + "/" + sortBy + "/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setTaskList(response.data.data.res);
            //setTotalItems(response.data.data.totalCount);
            console.log(response.data.data.res);
          } else setTaskList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function getAllCategoryList(searchString) {
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "category/getcategorylist/categoryid/asc/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            // console.log("my deam...."+response.data.data.res)
            setCategoryList(response.data.data.res);
            setCategoryID(response.data.data.res[0].categoryid);
            getSelectCount(response.data.data.res[0].categoryid);
            setFrequancyID(response.data.data.res[0].frequancy_ids);

            makeGetRequest(
              "schedular/getschedularCategory/" +
                parseInt(response.data.data.res[0].categoryid)
            )
              .then((response) => {
                if (response.data.data) {
                  if (response.data.data.length > 0) {
                    //console.log("my deam...."+response.data.data.res)
                    setFreequancyList(response.data.data);
                    getAllTaskList(null);
                  } else {
                    setFreequancyList([
                      {
                        frequancyid: 0,
                        frequancy_name: "Any",
                        status: true,
                        is_deleted: false,
                        created_at: "2024-08-29T05:29:23.767Z",
                        updated_at: "2024-09-20T12:12:10.157Z",
                        categoryid: response.data.data.res[0].categoryid,
                      },
                    ]);
                  }
                  setUpload(!upload);
                }
                //setShowLoader(false);
              })
              .catch((err) => {
                setShowLoader(false);
              });
          } else setCategoryList([]);
          setUpload(!upload);
        }
        //setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  React.useEffect(() => {
    if (categoryid == null || categoryid == "" || categoryid.length == 0) {
      getAllCategoryList(null);
    }
  }, []);

  return (
    <>
      <div className="wrapper checkTaskAvailMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <AppDrawer />
            {/* <NavigateBeforeIcon onClick={() => navigate("/")} /> */}
          </div>
          <h1>Check Tasks Availability</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <div className="tabsMainD">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {/* <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Store Opening" {...a11yProps(0)} /
                  <Tab label="Shift Opening" {...a11yProps(1)} />
                  <Tab label="Daily" {...a11yProps(2)} />
                  <Tab label="Closing" {...a11yProps(3)} />
                </Tabs> */}
                {categoryList ? (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    {categoryList.map((catObj, i) => (
                      <Tab
                        label={catObj.category_name}
                        onClick={(e) => changeList(e, catObj.categoryid)}
                      />
                    ))}
                  </Tabs>
                ) : (
                  <></>
                )}
              </Box>
              {categoryList.map((catObj, i) => (
                <CustomTabPanel value={value} index={i}>
                  <div className="tabInrContDiv">
                    <div className="storOpeningMain">
                      <div className="listingSearch">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => getAllTaskList(e.target.value)}
                        />
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortBy();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>

                      <h1 className="tabSbHeading">
                        Total Tasks Selected <b>{incvalue}</b>
                      </h1>
                      {taskList ? (
                        <>
                          {taskList.map(
                            (
                              empObj,
                              index //selectedTaskList
                            ) => (
                              <>
                                {empObj.categoryid}

                                <div
                                  //className="employyeListingMain"
                                  style={{ marginTop: 10 }}
                                  id={
                                    "listPID-" +
                                    index +
                                    catObj.categoryid +
                                    empObj.taskid
                                  }
                                  className={
                                    add(selectedTaskList, empObj.taskid)
                                      ? "employyeListingMain selectedList"
                                      : "employyeListingMain"
                                  }
                                >
                                  <div
                                    // className={`swipe-container ${swipeDirection === "left" ? "swiped-left" : ""
                                    //   } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                                    className={"swipe-container"}
                                    onTouchStart={handleTouchStart}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={handleTouchEnd}
                                    //onClick={forExpand}
                                    id={
                                      "listID-" +
                                      index +
                                      catObj.categoryid +
                                      empObj.taskid
                                    }
                                    onClick={(e) =>
                                      forSelectList(
                                        e,
                                        index,
                                        empObj.taskid,
                                        catObj.categoryid
                                      )
                                    }
                                  >
                                    <div className="deleEdiBtLeft">
                                      {/* <IconButton>
                            <EditIcon />
                          </IconButton> */}
                                      <IconButton
                                        onClick={() => navigate("/TaskDetails")}
                                      >
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    </div>
                                    <div
                                      className="emploTaskListInr"
                                      id="listID"
                                      onClick={swipeNSelection}
                                    >
                                      <div className="withCheck">
                                        <VerifiedIcon />
                                        <h1>{empObj.task_name} </h1>
                                      </div>

                                      <div className="taskImUpload">
                                        {/* <FilterIcon onClick={handleClickOpen} /> */}
                                        {empObj.is_beforeafter == true ? (
                                          <div className="taskImUpload">
                                            <FilterIcon />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    {/* <div className="deleEdiBtRight">
                            <IconButton>
                              <DeleteIcon className="DeleteIcon" />
                            </IconButton>
                          </div> */}
                                  </div>
                                  <div
                                    className="expandDiv"
                                    id={
                                      "expandId-" +
                                      index +
                                      catObj.categoryid +
                                      empObj.taskid
                                    }
                                  >
                                    <div className="weeklyTaskTbl">
                                      <table width="100%">
                                        <thead>
                                          <tr>
                                            <th></th>
                                            <th>
                                              10 <br /> Sep
                                            </th>
                                            <th>
                                              11 <br /> Sep
                                            </th>
                                            <th>
                                              12 <br /> Sep
                                            </th>
                                            <th>
                                              13 <br /> Sep
                                            </th>
                                            <th>
                                              14 <br /> Sep
                                            </th>
                                            <th>
                                              15 <br /> Sep
                                            </th>
                                            <th>
                                              16 <br /> Sep
                                            </th>
                                          </tr>
                                          <tr>
                                            <th></th>
                                            <th>M</th>
                                            <th>T</th>
                                            <th>W</th>
                                            <th>T</th>
                                            <th>F</th>
                                            <th>S</th>
                                            <th>S</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* {rows.map((row) => (
                                  <tr key={row.id}>
                                    <td>
                                      {row.id == 1 ? (
                                        <>
                                          <input
                                            className="hideInpt"
                                            type="checkbox"
                                            checked={row.selectAll}
                                            onChange={() =>
                                              handleSelectAllChange(row.id)
                                            }
                                          />
                                          <b
                                            style={{ color: "#990000" }}
                                            className="omcTx"
                                          >
                                            O
                                          </b>
                                        </>
                                      ) : row.id == 2 ? (
                                        <>
                                          <input
                                            className="hideInpt"
                                            type="checkbox"
                                            checked={row.selectAll}
                                            onChange={() =>
                                              handleSelectAllChange(row.id)
                                            }
                                          />
                                          <b
                                            style={{ color: "#990000" }}
                                            className="omcTx"
                                          >
                                            M
                                          </b>
                                        </>
                                      ) : row.id == 3 ? (
                                        <>
                                          <input
                                            className="hideInpt"
                                            type="checkbox"
                                            checked={row.selectAll}
                                            onChange={() =>
                                              handleSelectAllChange(row.id)
                                            }
                                          />
                                          <b
                                            style={{ color: "#990000" }}
                                            className="omcTx"
                                          >
                                            C
                                          </b>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    {row.checkboxes.map((checked, index) => (
                                      <td key={index}>
                                        <Checkbox
                                          {...label}
                                          defaultChecked
                                          checked={checked}
                                          onChange={() =>
                                            handleCheckboxChange(row.id, index)
                                          }
                                        />
                                      </td>
                                    ))}
                                  </tr>
                                ))} */}

                                          {freequancyList ? (
                                            <>
                                              {freequancyList.map(
                                                (option, index) => (
                                                  <>
                                                    <tr>
                                                      <td>
                                                        {" "}
                                                        <input
                                                          className="hideInpt"
                                                          type="checkbox"
                                                          checked={
                                                            option.selectAll
                                                          }
                                                          onChange={() =>
                                                            handleSelectAllChange(
                                                              option.frequancyid
                                                            )
                                                          }
                                                        />
                                                        <b className="omcCheck">
                                                          <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                          />{" "}
                                                          <em className="txTrans">
                                                            {
                                                              option
                                                                .frequancy_name[0]
                                                            }
                                                            <div>
                                                              {option.frequancy_name.slice(
                                                                1
                                                              )}
                                                            </div>
                                                          </em>
                                                          <div className="shiftTiming">
                                                            <marquee scrollamount="2">
                                                              8:00 am - 12:00 pm
                                                            </marquee>
                                                          </div>
                                                        </b>
                                                      </td>

                                                      <td>
                                                        <Checkbox
                                                          id={
                                                            `mon` +
                                                            index +
                                                            "" +
                                                            empObj.taskid +
                                                            "" +
                                                            categoryid
                                                          }
                                                          checked={
                                                            selectedMenuOfMonday.frequancyid.includes(
                                                              option.frequancyid
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeWeekDayCheckBox(
                                                              e.target.checked,
                                                              index,
                                                              empObj.taskid,
                                                              "Monday",
                                                              option.frequancyid,
                                                              categoryid
                                                            )
                                                          }
                                                        />
                                                      </td>

                                                      <td>
                                                        <Checkbox
                                                          id={
                                                            `tues` +
                                                            index +
                                                            "" +
                                                            empObj.taskid +
                                                            "" +
                                                            categoryid
                                                          }
                                                          checked={
                                                            selectedScheduleOfTuesday.frequancyid.includes(
                                                              option.frequancyid
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeWeekDayCheckBox(
                                                              e.target.checked,
                                                              index,
                                                              empObj.taskid,
                                                              "Tuesday",
                                                              option.frequancyid,
                                                              categoryid
                                                            )
                                                          }
                                                        />
                                                      </td>

                                                      <td>
                                                        <Checkbox
                                                          id={
                                                            `wed` +
                                                            index +
                                                            "" +
                                                            empObj.taskid +
                                                            "" +
                                                            categoryid
                                                          }
                                                          checked={
                                                            selectedMenuOfWednesday.frequancyid.includes(
                                                              option.frequancyid
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeWeekDayCheckBox(
                                                              e.target.checked,
                                                              index,
                                                              empObj.taskid,
                                                              "Wednesday",
                                                              option.frequancyid,
                                                              categoryid
                                                            )
                                                          }
                                                        />
                                                      </td>

                                                      <td>
                                                        <Checkbox
                                                          id={
                                                            `thur` +
                                                            index +
                                                            "" +
                                                            empObj.taskid +
                                                            "" +
                                                            categoryid
                                                          }
                                                          checked={
                                                            selectedMenuOfThursday.frequancyid.includes(
                                                              option.frequancyid
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeWeekDayCheckBox(
                                                              e.target.checked,
                                                              index,
                                                              empObj.taskid,
                                                              "Thursday",
                                                              option.frequancyid,
                                                              categoryid
                                                            )
                                                          }
                                                        />
                                                      </td>

                                                      <td>
                                                        <Checkbox
                                                          id={
                                                            `fri` +
                                                            index +
                                                            empObj.taskid +
                                                            "" +
                                                            categoryid
                                                          }
                                                          checked={
                                                            selectedMenuOfFriday.frequancyid.includes(
                                                              option.frequancyid
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeWeekDayCheckBox(
                                                              e.target.checked,
                                                              index,
                                                              empObj.taskid,
                                                              "Friday",
                                                              option.frequancyid,
                                                              categoryid
                                                            )
                                                          }
                                                        />
                                                      </td>

                                                      <td className="blink_me">
                                                        <Checkbox
                                                          id={
                                                            `sat` +
                                                            index +
                                                            empObj.taskid +
                                                            "" +
                                                            categoryid
                                                          }
                                                          checked={
                                                            selectedMenuOfSaturday.frequancyid.includes(
                                                              option.frequancyid
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeWeekDayCheckBox(
                                                              e.target.checked,
                                                              index,
                                                              empObj.taskid,
                                                              "Saturday",
                                                              option.frequancyid,
                                                              categoryid
                                                            )
                                                          }
                                                        />
                                                      </td>

                                                      <td className="blink_me">
                                                        <Checkbox
                                                          id={
                                                            `sunday` +
                                                            index +
                                                            empObj.taskid +
                                                            "" +
                                                            categoryid
                                                          }
                                                          checked={
                                                            selectedMenuOfSunday.frequancyid.includes(
                                                              option.frequancyid
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeWeekDayCheckBox(
                                                              e.target.checked,
                                                              index,
                                                              empObj.taskid,
                                                              "Sunday",
                                                              option.frequancyid,
                                                              categoryid
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  </>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <span
                                      className="udateSheBt newSheBt"
                                      onClick={(e) =>
                                        updateSchedular(
                                          e,
                                          index,
                                          empObj.taskid,
                                          catObj.categoryid
                                        )
                                      }
                                    >
                                      Update
                                    </span>
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </CustomTabPanel>
              ))}
            </Box>
          </div>
        </div>

        <AdminBottomNav />
      </div>

      {/* Select Shift Timings */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={ShipTiming}
            onClose={handleCloseShipTiming}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Select Shift Timings</h1>
              <IconButton
                className="modalCloseIco"
                onClick={handleCloseShipTiming}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="selectShiptMain">
                    <div
                      id="forSelectShiptId"
                      className={`selectShiptInr ${
                        clickedId === 1 ? "forSelectShiptClass" : ""
                      }`}
                      onClick={() => handleClick(1)}
                    >
                      <VerifiedIcon />
                      Opening
                    </div>
                    <div
                      id="forSelectShiptId"
                      className={`selectShiptInr ${
                        clickedId === 2 ? "forSelectShiptClass" : ""
                      }`}
                      onClick={() => handleClick(2)}
                    >
                      <VerifiedIcon /> Mid-day
                    </div>
                    <div
                      id="forSelectShiptId"
                      className={`selectShiptInr ${
                        clickedId === 3 ? "forSelectShiptClass" : ""
                      }`}
                      onClick={() => handleClick(3)}
                    >
                      <VerifiedIcon /> Closing
                    </div>
                    {/* <Button className="okBtn" onClick={handleCloseShipTiming}>
                      Ok
                    </Button> */}
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef1} />
                      </div>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef2} />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CheckTasksAvailability;
