import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../../../screens/admin/AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import { Grid, Button } from "@mui/material";
import Slider from "react-slick";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import CloseIcon from "@mui/icons-material/Close";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AppDrawer from "../../AppDrawer";

// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import pencil from "../../../assets/images/pencil.svg";
import availabilityPreview from "../../../assets/images/availabilityPreview.svg";
import Switch from "@mui/material/Switch";
import avarPi from "../../../assets/images/avtarPic.png";
import UserBottom from "../UserBottom/UserBottom";

const label = { inputProps: { "aria-label": "Switch demo" } };

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

// Tab Inner
function CustomTabPanel2(props) {
  const { children, value2, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value2 !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value2 === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value2: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function PersonalTasks() {
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
  };

  // For Modal
  const [openAval, setOpenAval] = React.useState(false);

  const handleClickOpenAval = () => {
    setOpenAval(true);
  };

  const handleCloseAval = () => {
    setOpenAval(false);
  };
  // End

  // Tab Inner
  const [value2, setValue2] = React.useState(0);

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };
  // End

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(1);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [selected, setSelected] = React.useState(false);
  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(1000);
  const [totalItems, setTotalItems] = React.useState(0);
  const [categoryList, setCategoryList] = React.useState([]);
  const [taskList, setTaskList] = React.useState([]);
  const [frequancyList, setFrequancyList] = React.useState([]);
  const [searchText, setSearchText] = useState("");
  const [catsearchText, setCatSearchText] = useState("");
  const [fresearchText, setFreSearchText] = useState("");
  const [tempSlide, setTempSlide] = React.useState("");
  const [userId, setUserId] = React.useState(0);

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(searchText);
  }

  // For Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 0) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      getAllTaskList(null);
    } else if (newValue == 1) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      getAllFrequancyList(null);
    } else if (newValue == 2) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      getAllCategoryList(null);
    } else {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      getAllTaskList(null);
    }

    localStorage.removeItem("TabName");
  };
  // End

  // For Drop Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const OpenDrop = Boolean(anchorEl);
  const handleClickOpenDrop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOpenDrop = () => {
    setAnchorEl(null);
  };
  // End

  const swipeNSelection = (event) => {
    forSelectList(event);
    resetSwipeDirection(event);
  };

  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const handleTouchStart = (e, index) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e, index) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-right");
          element.classList.remove("swiped-left");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-right");
        setTempSlide(index);
        //setSwipeDirection("right");
      } else {
        //setSwipeDirection("left");
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-left");
          element.classList.remove("swiped-right");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-left");
        setTempSlide(index);
      }
    }
  };

  const handleTouchEnd = () => {
    var element = document.getElementById("bugfree-" + tempSlide);
    if (element != null) {
      element.classList.remove("swiped-right");
      element.classList.remove("swiped-left");
    }
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  async function getAllTaskList(searchString) {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    setShowLoader(true);
    let newuserid = 0;
    if (userId == 0) newuserid = parseInt(localStorage.getItem("userId"));
    else newuserid = userId;
    await makeGetRequest(
      "personaltask/tasksearch/" +
        newuserid +
        "/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setTaskList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("Task", response.data.data.totalCount);
          } else setTaskList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function deleteTask(taskId) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        taskid: taskId,
        is_deleted: true,
      };
      await makePostRequest("personaltask/updatetaskstatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllTaskList(searchText);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const editTask = (taskObj) => {
    navigate("/AddPersonalTasks", {
      state: { Id: taskObj.taskid },
    });
  };

  function handleChangeSortByTask() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(searchText);
  }

  async function getAllCategoryList(searchString) {
    if (!searchString) {
      searchString = null;
      setCatSearchText(null);
    } else {
      setCatSearchText(searchString);
    }
    setShowLoader(true);
    let newuserid = 0;
    if (userId == 0) newuserid = parseInt(localStorage.getItem("userId"));
    else newuserid = userId;
    await makeGetRequest(
      "personalcategory/categorysearch/" +
        newuserid +
        "/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("Category", response.data.data.totalCount);
          } else setCategoryList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function deleteCategory(categoryid) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        categoryid: categoryid,
        is_deleted: true,
      };
      await makePostRequest(
        "personalcategory/updatecategorystatus",
        bodyFormData
      )
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllCategoryList(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const editCategory = (cateObj) => {
    navigate("/PersonalAddCategory", {
      state: { data: cateObj },
    });
  };

  function handleChangeSortByCategory() {
    setSortColumnName("frequancy_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllCategoryList(catsearchText);
  }

  async function getAllFrequancyList(searchString) {
    if (!searchString) {
      searchString = null;
      setFreSearchText(null);
    } else {
      setFreSearchText(searchString);
    }
    setShowLoader(true);

    let newuserid = 0;
    if (userId == 0) newuserid = parseInt(localStorage.getItem("userId"));
    else newuserid = userId;
    await makeGetRequest(
      "personalfrequancy/frequancysearch/" +
        newuserid +
        "/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setFrequancyList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("frequancy", response.data.data.totalCount);
          } else setFrequancyList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function deleteFrequency(frequancyid) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        frequancyid: frequancyid,
        is_deleted: true,
      };
      await makePostRequest(
        "personalfrequancy/updatefrequancystatus",
        bodyFormData
      )
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllFrequancyList(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const editFrequency = (frequObj) => {
    navigate("/PersonalAddFrequency", {
      state: { data: frequObj },
    });
  };

  function handleChangeSortByFrequency() {
    setSortColumnName("frequancy_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllFrequancyList(fresearchText);
  }

  React.useEffect(() => {
    getAllCategoryList(null);
    getAllTaskList(null);
    getAllFrequancyList(null);
    let userid = localStorage.getItem("userId");
    setUserId(userid);

    if (localStorage.getItem("TabName") == "frequency") {
      setValue(1);
      a11yProps(1);
    } else if (localStorage.getItem("TabName") == "Task") {
      // alert("hiii")
      setValue(0);
      a11yProps(0);
    } else if (localStorage.getItem("TabName") == "Category") {
      setValue(2);
      a11yProps(2);
    } else {
      setValue(0);
      a11yProps(0);
    }
  }, [pageNo]);

  return (
    <>
      <div className="wrapper">
        <div className="taskListAdmMain forUpperBottomBt">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow forAppDrawer">
              <AppDrawer />
            </div>
            <h1>
              <marquee>Manage Personal Tasks & Category</marquee>
            </h1>
            {/* <IconButton
              className="addEmpTpBt"
              onClick={() => navigate("/AdminAddTask")}
            >
              <AddIcon />
            </IconButton> */}

            <div className="myFlotingBtMain adminTaskFloatingMain">
              <div class="fab-wrapper">
                <input id="fabCheckbox" type="checkbox" class="fab-checkbox" />
                <label class="fab" for="fabCheckbox">
                  <span class="fab-dots fab-dots-1"></span>
                  <span class="fab-dots fab-dots-2"></span>
                  <span class="fab-dots fab-dots-3"></span>
                </label>
                <div class="fab-wheel">
                  <a
                    class="fab-action fab-action-1"
                    onClick={() => navigate("/AddPersonalTasks")}
                  >
                    <span className="floatinTx">Add Personal Task</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-2"
                    onClick={() => navigate("/PersonalAddFrequency")}
                  >
                    <span className="floatinTx">Add Personal Frequency</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-3"
                    onClick={() => navigate("/PersonalAddCategory")}
                  >
                    <span className="floatinTx">Add Personal Category</span>
                    <AddIcon />
                  </a>
                </div>
              </div>
            </div>

            {/* <IconButton
              className="addEmpTpBt"
              id="demo-positioned-button"
              aria-controls={OpenDrop ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={OpenDrop ? "true" : undefined}
              onClick={handleClickOpenDrop}
            >
              <AddIcon />
            </IconButton>
            <Menu
              className="menuMainTs"
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={OpenDrop}
              onClose={handleCloseOpenDrop}
            >
              <MenuItem onClick={handleCloseOpenDrop}>
                <div onClick={() => navigate("/AdminAddTask")}>Add Task</div>
              </MenuItem>
              <MenuItem onClick={handleCloseOpenDrop}>
                <div onClick={() => navigate("/AddFrequency")}>
                  Add Frequency
                </div>
              </MenuItem>
              <MenuItem onClick={handleCloseOpenDrop}>
                <div onClick={() => navigate("/AddCategory")}>Add Category</div>
              </MenuItem>
            </Menu> */}
          </div>
          {/* TopBarEnd */}

          <div className="tabsMainD addTaskNCateTabMain">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Personal Tasks" {...a11yProps(0)} />
                  <Tab label="Personal Frequency" {...a11yProps(1)} />
                  <Tab label="Personal Categories" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div className="tabInrContDiv">
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllTaskList(e.target.value)}
                    />
                    <div onClick={() => handleChangeSortByTask()}>
                      <div className="sortingIcoOut">
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() => {
                            handleChangeSortBy();
                          }}
                        >
                          <ImportExportIcon
                            className="visitorFilterIco"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {taskList ? (
                      <>
                        {taskList.map((taskObj) => (
                          <div
                            id={"bugfree-" + taskObj.taskid}
                            // className={`swipe-container ${
                            //   swipeDirection === "left" ? "swiped-left" : ""
                            //   } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                            className="swipe-container"
                            onTouchStart={(e) =>
                              handleTouchStart(e, taskObj.taskid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, taskObj.taskid)
                            }
                            onClick={(e) => handleTouchEnd(e, taskObj.taskid)}
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton onClick={() => editTask(taskObj)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate("/PersonalTaskDetails", {
                                    state: { Id: taskObj.taskid },
                                  })
                                }
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div>
                                <h1>{taskObj.task_name}</h1>
                              </div>
                              <div className="taskImUpload">
                                {/* <FilterIcon onClick={handleClickOpen} /> */}
                                {taskObj.is_beforeafter == true ? (
                                  <div className="taskImUpload">
                                    <FilterIcon />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {/* <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon onClick={handleClickOpen} />
                        </div> */}
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>
                              <IconButton
                                onClick={() => deleteTask(taskObj.taskid)}
                              >
                                <DeleteIcon className="DeleteIcon" />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div> */}
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <div className="tabInrContDiv">
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllFrequancyList(e.target.value)}
                    />
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onClick={() => handleChangeSortByFrequency()}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {frequancyList ? (
                      <>
                        {frequancyList.map((frequObj) => (
                          <div
                            // className={`swipe-container ${
                            //   swipeDirection === "left" ? "swiped-left" : ""
                            // } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                            id={"bugfree-" + frequObj.frequancyid}
                            className={"swipe-container"}
                            onTouchStart={(e) =>
                              handleTouchStart(e, frequObj.frequancyid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, frequObj.frequancyid)
                            }
                            onClick={(e) =>
                              handleTouchEnd(e, frequObj.frequancyid)
                            }
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton
                                onClick={() => editFrequency(frequObj)}
                              >
                                <EditIcon />
                              </IconButton>
                              {/* <IconButton
                        // onClick={() => navigate("/TaskDetails")}
                        >
                          <InfoOutlinedIcon />
                        </IconButton> */}
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div className="activDeactSt">
                                <h1>{frequObj.frequancy_name}</h1>
                                <span>
                                  {frequObj.status == true ? (
                                    <p className="actSt">Active</p>
                                  ) : (
                                    <p className="deActSt">Deactive</p>
                                  )}
                                </span>
                              </div>
                              {/* <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon onClick={handleClickOpen} />
                        </div> */}
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  deleteFrequency(frequObj.frequancyid)
                                }
                              >
                                <DeleteIcon className="DeleteIcon" />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/*<div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div>*/}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <div className="tabInrContDiv">
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllCategoryList(e.target.value)}
                    />
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onClick={() => handleChangeSortByCategory()}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {categoryList ? (
                      <>
                        {categoryList.map((cateObj) => (
                          <div
                            id={"bugfree-" + cateObj.categoryid}
                            // className={`swipe-container ${
                            //   swipeDirection === "left" ? "swiped-left" : ""
                            //   } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                            className={"swipe-container"}
                            onTouchStart={(e) =>
                              handleTouchStart(e, cateObj.categoryid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, cateObj.categoryid)
                            }
                            onClick={(e) =>
                              handleTouchEnd(e, cateObj.categoryid)
                            }
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton onClick={() => editCategory(cateObj)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate("/PersonalCategoryDetails", {
                                    state: { Id: cateObj.categoryid },
                                  })
                                }
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div>
                                <h1>{cateObj.category_name}</h1>
                              </div>
                              {/* <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon onClick={handleClickOpen} />
                        </div> */}
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  deleteCategory(cateObj.categoryid)
                                }
                              >
                                <DeleteIcon className="DeleteIcon" />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <div className="tabInrContDiv">
                  <div className="schedulAcordianMain">
                    <div className="schedulAcordianInr">
                      <div className="schedulAcordianTp">
                        <b>
                          Summer Shift{" "}
                          <IconButton aria-label="edit">
                            <img src={pencil} />
                          </IconButton>
                        </b>
                        <div className="schedulAcordianTpRight">
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleClickOpenAval("body")}
                          >
                            <img src={availabilityPreview} />
                          </IconButton>
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                      <div className="schedulAcordianCont">
                        <div className="tabsMainD">
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <Tabs
                                value={value2}
                                onChange={handleChange2}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                              >
                                <Tab label="All" {...a11yProps2(0)} />
                                <Tab label="Kitchen" {...a11yProps2(1)} />
                                <Tab label="Dining" {...a11yProps2(2)} />
                                <Tab label="Front Office" {...a11yProps2(3)} />
                                <Tab label="At Gate" {...a11yProps2(4)} />
                              </Tabs>
                            </Box>
                            <CustomTabPanel value={value2} index={0}>
                              <div className="tabInrContDiv">
                                <div className="shiftDaysMain">
                                  <div className="shiftDaysInr">
                                    <div className="weeklyTaskTbl">
                                      <table width="100%">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <b>
                                                D<em>ays</em>
                                              </b>
                                            </td>
                                            <td>M</td>
                                            <td>T</td>
                                            <td>W</td>
                                            <td>T</td>
                                            <td>F</td>
                                            <td>S</td>
                                            <td style={{ color: "red" }}>S</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>
                                                S<em>hifts</em>
                                              </b>
                                            </td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>
                                                E<em>mp</em>
                                              </b>
                                            </td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={1}>
                              222222
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={2}>
                              33333
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={3}>
                              44444
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={4}>
                              55555
                            </CustomTabPanel>
                          </Box>
                        </div>
                      </div>
                    </div>

                    <div className="schedulAcordianInr">
                      <div className="schedulAcordianTp">
                        <b>
                          Winter Shift{" "}
                          <IconButton aria-label="edit">
                            <img src={pencil} />
                          </IconButton>
                        </b>
                        <div className="schedulAcordianTpRight">
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleClickOpenAval("body")}
                          >
                            <img src={availabilityPreview} />
                          </IconButton>
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                      <div className="schedulAcordianCont">
                        <div className="tabsMainD">
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <Tabs
                                value={value2}
                                onChange={handleChange2}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                              >
                                <Tab label="All" {...a11yProps2(0)} />
                                <Tab label="Kitchen" {...a11yProps2(1)} />
                                <Tab label="Dining" {...a11yProps2(2)} />
                                <Tab label="Front Office" {...a11yProps2(3)} />
                                <Tab label="At Gate" {...a11yProps2(4)} />
                              </Tabs>
                            </Box>
                            <CustomTabPanel value={value2} index={0}>
                              <div className="tabInrContDiv">
                                <div className="shiftDaysMain">
                                  <div className="shiftDaysInr">
                                    <div className="weeklyTaskTbl">
                                      <table width="100%">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <b>
                                                D<em>ays</em>
                                              </b>
                                            </td>
                                            <td>M</td>
                                            <td>T</td>
                                            <td>W</td>
                                            <td>T</td>
                                            <td>F</td>
                                            <td>S</td>
                                            <td style={{ color: "red" }}>S</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>
                                                S<em>hifts</em>
                                              </b>
                                            </td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>
                                                E<em>mp</em>
                                              </b>
                                            </td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={1}>
                              222222
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={2}>
                              33333
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={3}>
                              44444
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={4}>
                              55555
                            </CustomTabPanel>
                          </Box>
                        </div>
                      </div>
                    </div>

                    <div className="schedulAcordianInr">
                      <div className="schedulAcordianTp">
                        <b>
                          Fall Shift{" "}
                          <IconButton aria-label="edit">
                            <img src={pencil} />
                          </IconButton>
                        </b>
                        <div className="schedulAcordianTpRight">
                          <IconButton aria-label="edit">
                            <img src={availabilityPreview} />
                          </IconButton>
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                      <div className="schedulAcordianCont">
                        <div className="tabsMainD">
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <Tabs
                                value={value2}
                                onChange={handleChange2}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                              >
                                <Tab label="All" {...a11yProps2(0)} />
                                <Tab label="Kitchen" {...a11yProps2(1)} />
                                <Tab label="Dining" {...a11yProps2(2)} />
                                <Tab label="Front Office" {...a11yProps2(3)} />
                                <Tab label="At Gate" {...a11yProps2(4)} />
                              </Tabs>
                            </Box>
                            <CustomTabPanel value={value2} index={0}>
                              <div className="tabInrContDiv">
                                <div className="shiftDaysMain">
                                  <div className="shiftDaysInr">
                                    <div className="weeklyTaskTbl">
                                      <table width="100%">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <b>
                                                D<em>ays</em>
                                              </b>
                                            </td>
                                            <td>M</td>
                                            <td>T</td>
                                            <td>W</td>
                                            <td>T</td>
                                            <td>F</td>
                                            <td>S</td>
                                            <td style={{ color: "red" }}>S</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>
                                                S<em>hifts</em>
                                              </b>
                                            </td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>
                                                E<em>mp</em>
                                              </b>
                                            </td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={1}>
                              222222
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={2}>
                              33333
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={3}>
                              44444
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={4}>
                              55555
                            </CustomTabPanel>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
            </Box>
          </div>

          <UserBottom />
        </div>
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef1} />
                      </div>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef2} />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {/* Availabiliy */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={openAval}
            onClose={handleCloseAval}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Summer Shift Availability</h1>
              <IconButton className="modalCloseIco" onClick={handleCloseAval}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="shifAvailabiMain">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <div className="mySlik createScheduSlik">
                          <div className="slider-container">
                            <Slider {...settings}>
                              <div>
                                <h3>Mon</h3>
                                <span>10/07/2024</span>
                              </div>
                              <div>
                                <h3>Tue</h3>
                                <span>11/07/2024</span>
                              </div>
                              <div>
                                <h3>Wed</h3>
                                <span>12/07/2024</span>
                              </div>
                              <div>
                                <h3>Thu</h3>
                                <span>13/07/2024</span>
                              </div>
                              <div>
                                <h3>Fri</h3>
                                <span>14/07/2024</span>
                              </div>
                              <div>
                                <h3>Sat</h3>
                                <span>15/07/2024</span>
                              </div>
                              <div>
                                <h3>Sun</h3>
                                <span>16/07/2024</span>
                              </div>
                            </Slider>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div
                          className="schedulAcordianMain"
                          style={{ marginBottom: 20 }}
                        >
                          <div className="schedulAcordianInr">
                            <div className="schedulAcordianTp">
                              <b>Kitchen</b>
                              <div className="schedulAcordianTpRight">
                                <ErrorOutlineIcon style={{ color: "red" }} />
                              </div>
                            </div>
                            <div className="schedulAcordianCont">
                              <div className="ShifPreviewModCont">
                                <h1>09:30am - 12:30pm (2/2)</h1>
                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>

                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>

                                <h1>09:30am - 12:30pm (3/2)</h1>

                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>

                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>

                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>

                                <h1 className="contError">
                                  09:30am - 12:30pm (2/3){" "}
                                  <ErrorOutlineIcon style={{ color: "red" }} />
                                </h1>

                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>

                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="schedulAcordianInr">
                            <div className="schedulAcordianTp">
                              <b>Housekeeping</b>
                              <div className="schedulAcordianTpRight">
                                <PlaylistAddCheckIcon
                                  style={{ color: "#0FB000", fontSize: 27 }}
                                />
                              </div>
                            </div>
                            <div className="schedulAcordianCont">
                              <div className="ShifPreviewModCont">
                                <h1>09:30am - 12:30pm (2/2)</h1>
                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>

                                <div className="connectionsDiv">
                                  <div className="pendingReIm">
                                    <img src={avarPi} />
                                  </div>
                                  <div className="pendingRex">
                                    <h1>Rhea Agarwal</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default PersonalTasks;
