// import React, { Component } from "react";
import React, { useState, useRef } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

function SliderDemo() {
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50; // Minimum distance in pixels to consider a swipe

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        setSwipeDirection("right");
      } else {
        setSwipeDirection("left");
      }
    }
  };

  const handleTouchEnd = () => {
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };

  // const settings = {
  //   className: "center",
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: "60px",
  //   slidesToShow: 3,
  //   speed: 500,
  // };
  return (
    // <div className="mySlik">
    //   <div className="slider-container">
    //     <Slider {...settings}>
    //       <div>
    //         <h3>1</h3>
    //       </div>
    //       <div>
    //         <h3>2</h3>
    //       </div>
    //       <div>
    //         <h3>3</h3>
    //       </div>
    //       <div>
    //         <h3>4</h3>
    //       </div>
    //       <div>
    //         <h3>5</h3>
    //       </div>
    //       <div>
    //         <h3>6</h3>
    //       </div>
    //     </Slider>
    //   </div>
    // </div>

    <>
      <div
        className={`swipe-container ${
          swipeDirection === "left" ? "swiped-left" : ""
        } ${swipeDirection === "right" ? "swiped-right" : ""}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          width: "300px",
          height: "200px",
          backgroundColor: "#f0f0f0",
          border: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s ease",
          position: "relative",
          touchAction: "none", // Prevent default touch actions
        }}
      >
        Swipe Here
      </div>
    </>
  );
}

export default SliderDemo;
