import React, { useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";

// Sortable Item Component
const SortableItem = SortableElement(({ value }) => (
  <li
    style={{
      padding: "10px",
      margin: "10px 10px",
      border: "1px solid #ccc",
      cursor: "pointer",
      background: "#eee",
    }}
  >
    {value}
  </li>
));

// Sortable List Component
const SortableList = SortableContainer(({ items }) => {
  return (
    <ul style={{ listStyleType: "none", padding: 0 }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

// Main Component
const DragNDrop = () => {
  const [items, setItems] = useState([
    "Item 1",
    "Item 2",
    "Item 3",
    "Item 4",
    "Item 5",
    "Item 6",
    "Item 7",
    "Item 8",
    "Item 9",
    "Item 10",
  ]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems((prevItems) => arrayMove(prevItems, oldIndex, newIndex));
  };

  return (
    <div>
      <SortableList items={items} onSortEnd={onSortEnd} />
    </div>
  );
};

export default DragNDrop;
