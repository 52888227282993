import React from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";

function FloatButton(props) {
  const navigate = useNavigate();
  return (
    <div className="myFlotingBtMain">
      <div class="fab-wrapper">
        <input id="fabCheckbox" type="checkbox" class="fab-checkbox" />
        <label class="fab" for="fabCheckbox">
          <span class="fab-dots fab-dots-1"></span>
          <span class="fab-dots fab-dots-2"></span>
          <span class="fab-dots fab-dots-3"></span>
        </label>
        <div class="fab-wheel">
          <a
            class="fab-action fab-action-1"
            onClick={() => navigate(`/${props.route1}`)}
          >
            <ManageAccountsIcon />
          </a>
          <a class="fab-action fab-action-2">
            <ManageAccountsIcon />
          </a>
          <a class="fab-action fab-action-3">
            <ManageAccountsIcon />
          </a>
          <a class="fab-action fab-action-4">
            <ManageAccountsIcon />
          </a>
        </div>
      </div>
    </div>
  );
}

export default FloatButton;
