import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../../../screens/admin/AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment/moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import underContruction from "../../../assets/images/under-construction.gif";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FloatButton from "../../FloatButton";
import AppDrawer from "../../AppDrawer";
import noPic from "../../../assets/images/no-data.png";
import unlock from "../../../assets/images/unlock.png";
import lock from "../../../assets/images/lock.png";
import swal from "sweetalert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import avatarPic from "../../../assets/images/avtarPic.png";
import avtarp from "../../../assets/images/avtarPic.png";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Switch from "@mui/material/Switch";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Person2Icon from "@mui/icons-material/Person2";
import UserBottom from "../UserBottom/UserBottom";
import UserBottomForPersonalView from "../UserBottom/UserBottomForPersonalView";
import avtarPic from "../../../assets/images/Profile-Picture.jpg";
import imageCompression from "browser-image-compression";
import CreateIcon from "@mui/icons-material/Create";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ENd

function getDatesArray(startDate, endDate) {
  const dates = [];
  const start = moment(startDate);
  const end = moment(endDate);

  while (start.isSameOrBefore(end)) {
    dates.push({
      date: start.format("YYYY-MM-DD"),
      day: start.format("ddd"),
      formatdate: start.format("DD"),
    });
    start.add(1, "day");
  }

  return dates;
}

function EmployeeTaskListNew() {
  const navigate = useNavigate();

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [selected, setSelected] = React.useState(false);
  const [sortColumnName, setSortColumnName] = React.useState("task_name");
  const [sortBy, setSortBy] = React.useState("asc");
  const [upload, setUpload] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [categoryid, setCategoryID] = React.useState([]);
  const [categoryname, setCategoryName] = React.useState([]);
  const [frequancyid, setFrequancyID] = React.useState([]);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [slider1, setSlider1] = useState(null);
  const [mondaysCal, setMondaysCal] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [tempSlide, setTempSlide] = React.useState("");
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  const [currentweekCal, setCurrentWeekCal] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );
  const [startADate, setStartADate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment().endOf("year").add(1, "year")
  );
  const [datesArray, setDatesArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [UserId, setUserId] = useState();
  const [weekDay, setWeekDay] = useState(moment().format("dddd"));
  const [employeeTaskList, setEmployeeTaskList] = useState([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [completePercentage, setCompletePercentage] = useState(0);
  const [completeIteration, setCompleteIteration] = useState(0);
  const [growthPercentage, setGrowthPercentage] = useState(10);
  const [avaialableEmployee, setAvaialableEmployee] = useState([]);
  const [availablecnt, setAvailableCnt] = useState(0);
  const [localImage, setLocalImage] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [UserName, setUserName] = useState();
  const [companypic, setCompanyPic] = useState();
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const initialValues = [100];
  const [imageUploadVariables, setImageUploadVariables] = useState({
    taskid: "",
    userid: "",
  });

  const [imageBeforeErrMsg, setImageBeforeErrMsg] = React.useState("");
  const [imageAfterErrMsg, setImageAfterErrMsg] = React.useState("");

  async function compressImageBefore(file) {
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      const uploadDone = await uploadImageOrFile(renamedFile);
      setImageUploadBefore([...ImageUploadBefore, uploadDone]);
      setShowLoader(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadImagesBefore(e) {
    setImageBeforeErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];

    //console.log(e.target.file[0].size);
    let validateRes = false;
    if (await validateImage(file)) {
      if (await compressImageBefore(file)) {
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
      // var logoUrl = await uploadImageOrFile(file);
      // setImageUploadBefore([...ImageUploadBefore, logoUrl]);
    } else {
      setImageBeforeErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    setShowLoader(false);
  }

  const deleteImgBefore = (index) => {
    setShowLoader(true);
    ImageUploadBefore.splice(index, 1);
    setImageUploadBefore([...ImageUploadBefore]);
    setShowLoader(false);
  };

  async function compressImageAfter(file) {
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      const uploadDone = await uploadImageOrFile(renamedFile);
      setImageUploadAfter([...ImageUploadAfter, uploadDone]);
      setShowLoader(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadImagesAfter(e) {
    setImageAfterErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];

    //console.log(e.target.file[0].size);
    let validateRes = false;
    if (await validateImage(file)) {
      if (await compressImageAfter(file)) {
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
      //var logoUrl = await uploadImageOrFile(file);
      //setImageUploadAfter([...ImageUploadAfter, logoUrl]);
    } else {
      setImageAfterErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    setShowLoader(false);
  }

  const deleteImgAfter = (index) => {
    setShowLoader(true);
    ImageUploadAfter.splice(index, 1);
    setImageUploadAfter([...ImageUploadAfter]);
    setShowLoader(false);
  };

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    console.log(fileData);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;
    await uploadFileRequest("superadmin/upload", formData)
      .then((response) => {
        fileUrl = response.data.imagepath;
      })
      .catch((err) => {
        setShowLoader(false);
        let errMsg = err.response.data.message;
        console.log(err.response.data.message);
      });
    setShowLoader(false);
    console.log("upload", fileUrl);
    return fileUrl;
  }

  async function validateImage(fileData) {
    const fileSize = fileData.size; // Size in bytes
    const fileName = fileData.name;
    console.log("File Name", fileName, "File Size", fileSize);
    console.log("fileData", fileData.size);
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
      return true;
    } else return false;
  }

  const [employeeImageErrMsg, setEmployeeImageErrMsg] = React.useState("");

  async function compressProfileImage(file) {
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      const uploadDone = await uploadImageOrFile(renamedFile);
      let id = localStorage.getItem("userId");
      await uploadProfilepic(uploadDone, id);
      setShowLoader(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadImage(e) {
    setEmployeeImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    if (await validateImage(file)) {
      if (await compressProfileImage(file)) {
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
      /* console.log("After Validate", file);
        const imageUpload = await uploadImageOrFile(file);
        console.log("image Upload", imageUpload)
        let id = localStorage.getItem("userId");
        await uploadProfilepic(imageUpload, id); */
    } else {
      setEmployeeImageErrMsg("Invalid file format");
    }
    //updateImage();
    setShowLoader(false);
  }

  const uploadProfilepic = (Img, id) => {
    console.log(Img);
    console.log(id);

    const formData = {
      employee_id: parseInt(id),
      profile_pic: Img,
      contact_no: employeeDetails.contact_no,
      email_id: employeeDetails.email_id,
      company_id: employeeDetails.company_id,
      employee_code: employeeDetails.employee_code,
      schemaname: employeeDetails.schemaname,
    };
    makePostRequest("employee/updateemployee", formData)
      .then((response) => {
        if (response.data) {
          swal("Success", "Image updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
        }
        let id = localStorage.getItem("userId");
        getEmployeeDetails(id);
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        console.log(err.response.data.message);
      });
  };

  const updateTaskSchedular = () => {
    console.log("ImgVariable", imageUploadVariables);
    setImageUploadAfter([]);
    setImageUploadBefore([]);
    setShowLoader(false);
    /* values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.taskassigndatetime = moment().format('YYYY-MM-DD'); */
    let formData = {
      taskid: imageUploadVariables.taskid,
      empid: parseInt(imageUploadVariables.userid),
      categoryid: parseInt(dropDownSelect),
      before_img: ImageUploadBefore,
      after_img: ImageUploadAfter,
      taskassigndatetime: moment().format("YYYY-MM-DD"),
    };
    makePostRequest("employeeschedular/updateassigntask", formData)
      .then((response) => {
        if (response.data) {
          getSerachList();
          setLoginErrMsg("");
          setShowLoader(false);
          swal("Success", "Information updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
          handleClose();
          setImageUploadAfter([]);
          setImageUploadBefore([]);
        }
      })
      .catch((err) => {
        let errMsg = err.message;
        setShowLoader(false);
        setLoginErrMsg(errMsg);
      });
  };

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getSerachList(searchText);
  }

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (taskid, userId, taskObj) => {
    console.log("TaskId", taskid, "Userid", userId);
    console.log(taskObj);
    if (taskObj.assigntask != undefined) {
      if (taskObj.assigntask[0].empid == userId) {
        if (taskObj.assigntask[0].before_img != null)
          setImageUploadBefore([...taskObj.assigntask[0].before_img]);
        else setImageUploadBefore([]);
        if (taskObj.assigntask[0].after_img != null)
          setImageUploadAfter([...taskObj.assigntask[0].after_img]);
        else setImageUploadAfter([]);
        setImageUploadVariables({
          taskid: taskid,
          userid: userId,
        });
        setOpen(true);
      } else {
        swal(
          "This Task is Already Assigned to Someone else ",
          "Warning",
          "warning",
          {
            timer: 3000,
            buttons: false,
          }
        );
        setOpen(false);
      }
    } else {
      swal("Please assign Task First", "Warning", "warning", {
        timer: 3000,
        buttons: false,
      });
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  function getArrowDatesArray(startDate, endDate) {
    const dates = [];
    const start = moment(startDate);
    const end = moment(endDate);

    while (start.isSameOrBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, "day");
    }

    //console.log("new dates===>"+JSON.stringify(dates))

    return dates;
  }

  const datesArrayVal = getDatesArray(startDate, endDate);
  const today = moment();
  const todayIndex = datesArrayVal.findIndex((arraydate) =>
    moment(arraydate.date).isSame(today, "day")
  );

  const settings = {
    initialSlide: todayIndex,
    // className: "center",
    // centerMode: true,
    // infinite: true,
    // centerPadding: "0px",
    // slidesToShow: 3,
    // speed: 500,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,

    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "20px",
    infinite: true,
    autoplaySpeed: 5000,
    //autoplay: true,
    // nextArrow: <NextArrow />,
  };

  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  function getMonday(d) {
    var date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End

  function endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 7;
    return new Date(date.setDate(lastday)).toISOString().slice(0, 10);
  }

  function getSelectedWeek(startDate) {
    let week = [];
    var newdates = new Date(startDate);
    var d = new Date(startDate);
    d.setDate(1);
    var curr = getMonday(newdates);
    var xday = curr.getDate();
    d.setDate(xday);

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push({ CurrentWeek: day });
    }
    setCurrentWeekCal(week);
  }

  function SelectSliders(idx, obs) {
    setStartADate(obs);
    const date = moment(obs);
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    slider1?.slickGoTo(idx);
    ChangeDateFun(categoryid, obs, dayName);
  }

  const changeList = (e, id, catname) => {
    //setShowLoader(true);
    setCategoryID(id);
    setCategoryName(catname);
    const obsdate = moment(todaysDate.time).format("YYYY-MM-DD");
    setStartADate(moment(todaysDate.time).format("YYYY-MM-DD"));
    const date = moment(moment(todaysDate.time).format("YYYY-MM-DD"));
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    ChangeDateFun(id, obsdate, dayName);
  };

  const handleClick = async (index, taskid, empid, taskstatus) => {
    setClickCount((prevCount) => (prevCount + 1) % 4); // Cycles through 0 to 3
    setShowLoader(true);
    let values = {};
    if (taskstatus == "Open") values.taskstatus = "Assigned";
    else if (taskstatus == "Assigned") values.taskstatus = "Completed";
    else if (taskstatus == "Completed") values.taskstatus = "Open";
    else values.taskstatus = "Open";

    values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.taskassigndatetime = moment().format("YYYY-MM-DD");

    await makeGetRequest(
      "employeeschedular/selectassigntask/" +
        parseInt(empid) +
        "/" +
        parseInt(taskid) +
        "/" +
        parseInt(dropDownSelect) +
        "/'" +
        startDate +
        "'"
    )
      .then((response) => {
        console.log(
          "new response===>" + JSON.stringify(response.data.data.rows)
        );
        if (response.data.data.rowCount > 0) {
          let result = response.data.data.rows;
          if (result[0].assignid > 0 && result[0].taskstatus != "Completed") {
            makePostRequest("employeeschedular/updateassigntask", values)
              .then((response) => {
                if (response.data) {
                  // if(taskstatus == "Assigned")
                  // {
                  //   getStatusSerachList();
                  // }else{
                  getSerachList();
                  //}

                  setLoginErrMsg("");
                  setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            makeGetRequest(
              "employeeschedular/deleteassigntask/" +
                parseInt(empid) +
                "/" +
                parseInt(taskid) +
                "/" +
                parseInt(dropDownSelect) +
                "/'" +
                startDate +
                "'"
            )
              .then((response) => {
                getSerachList();
                setLoginErrMsg("");
                setShowLoader(false);
              })
              .catch((err) => {
                getSerachList();
                setLoginErrMsg("");
                setShowLoader(false);
              });
          }
        } else {
          if (values.taskstatus != "Open") {
            makePostRequest("employeeschedular/insertassigntask", values)
              .then((response) => {
                if (response.data) {
                  getSerachList();
                  setLoginErrMsg("");
                  setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            getSerachList();
            setShowLoader(false);
          }
        }
      })
      .catch((err) => {});
  };

  const handleskip = async (event, fullname) => {
    swal(
      "Already Assigned",
      `This task is already assigned to ${fullname}`,
      "info",
      {
        timer: 3000,
        buttons: false,
      }
    );
  };

  const getEmployeeDetails = async (id) => {
    setShowLoader(true);
    await makeGetRequest("/employee/getemployee/" + parseInt(id))
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0]);
            setEmployeeDetails(response.data.data[0]);
          } else setEmployeeDetails([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getEmployeeSchedulewithexisting = (id, catids) => {
    setShowLoader(true);
    makeGetRequest(
      "employeeschedular/employeesschedular/" + id + "/'" + startDate + "'"
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);

            setShowLoader(true);
            makeGetRequest(
              "employeeschedular/employeeassigntask/" +
                id +
                "/'" +
                startDate +
                "'/" +
                catids +
                "/'" +
                response.data.data.rows[0].weekday +
                "'" +
                "/" +
                sortColumnName +
                "/" +
                sortBy +
                "/" +
                null
            )
              .then((response) => {
                //console.log(response.data.data.rows);
                if (response.data.data.res.rows) {
                  //console.log(response.data.data);
                  //alert(response.data.data.rows[0].categories.length);
                  if (response.data.data.res.rows.length > 0) {
                    setEmployeeTaskList(response.data.data.res.rows);
                    setCompleteCount(
                      response.data.data.rescounts.rows[0].total_count
                    );
                    let totalTask = response.data.data.res.rowCount;
                    let completedTask =
                      response.data.data.rescounts.rows[0].total_count;
                    let Total = (completedTask * 100) / totalTask;
                    let FinalTotal = Math.round(Total);
                    setCompletePercentage(FinalTotal);
                    const iterations = FinalTotal / 10;
                    setCompleteIteration(iterations);
                    setShowLoader(false);
                  } else {
                    setEmployeeTaskList([]);
                    setCompletePercentage(0);
                    const iterations = 0;
                    setCompleteIteration(iterations);
                    setShowLoader(false);
                  }
                  setUpload(!upload);
                }
              })
              .catch((err) => {
                setShowLoader(false);
              });
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getStatusSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereportstatustask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          if (response.data.data.res.rows) {
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              //console.log("Sagar",response.data.data.res.rows)
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              if (searchString) {
                setCompleteCount(0);
              }
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const getSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          if (response.data.data.res.rows) {
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              //console.log("Sagar",response.data.data.res.rows)
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              if (searchString) {
                setCompleteCount(0);
              }
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const [ImageUploadBefore, setImageUploadBefore] = React.useState([]);
  const [ImageUploadAfter, setImageUploadAfter] = React.useState([]);

  async function getCompletedTaskCount(catid, startdate) {
    await makeGetRequest(
      "employeeschedular/selectreporttask/" + catid + "/" + startdate
    )
      .then((response) => {
        console.log("My available employee list==>" + JSON.stringify(response));
        if (response.data.data.rows) {
          if (response.data.data.rows.length > 0) {
            setAvaialableEmployee(response.data.data.rows);
            setAvailableCnt(response.data.data.rowCount);
            //setShowLoader(false);
          } else {
            setAvaialableEmployee([]);
            setAvailableCnt(0);
            //setShowLoader(false);
          }

          setUpload(!upload);
        }
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  const statusChangeFun = async (catid) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          catid +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].after_img)
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, startADate);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
      //setShowLoader(false);
    }
  };

  const ChangeDateFun = async (catid, searchdate, searchday) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          searchdate +
          "'/" +
          catid +
          "/'" +
          searchday +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar", response.data.data.res.rows[0].assigntask[0].after_img)
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].before_img)

              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, searchdate);
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const getEmployeeSchedule = (id) => {
    setShowLoader(true);
    //setCategoryID(id)

    makeGetRequest(
      "employeeschedular/employeesschedular/" + id + "/'" + startDate + "'"
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          //alert(response.data.data.rows[0].categories.length);
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);
            localStorage.setItem(
              "employeetaskcatid",
              response.data.data.rows[0].categories[0].categoryid
            );
            setDropDownSelect(
              response.data.data.rows[0].categories[0].categoryid
            );
            getEmployeeSchedulewithexisting(
              id,
              parseInt(response.data.data.rows[0].categories[0].categoryid)
            );
            //setWeekDay("Wednesday")
            //setSelectedTaskList(response.data.data.res.rows)
            //setIncValue(response.data.data.totalCount)
            //setShowLoader(false);
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  async function getAllCategoryList(searchString) {
    if (!searchString) searchString = null;
    //setShowLoader(true);
    await makeGetRequest(
      "category/getcategorylist/categoryid/asc/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);

            setCategoryID(response.data.data.res[0].categoryid);
            setCategoryName(response.data.data.res[0].category_name);
            setFrequancyID(response.data.data.res[0].frequancy_ids);

            const datesArray = getDatesArray(startDate, endDate);
            setMondaysCal(datesArray);
            statusChangeFun(response.data.data.res[0].categoryid);
            //setShowLoader(false);
          } else {
            setCategoryList([]);
            //setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    let id = localStorage.getItem("userId");

    getEmployeeSchedule(id);
    setInterval(() => setTodaysDate({ time: new Date() }), 1000);
    setUserName(localStorage.getItem("UserName"));
    setLocalImage(localStorage.getItem("adminProfilePic"));
    setCompanyPic(localStorage.getItem("companyLogo"));
    getEmployeeDetails(id);
  }, []);

  // React.useEffect(() => {
  //   setInterval(() => setTodaysDate({ time: new Date() }), 1000);
  //   if (categoryid == null || categoryid == "" || categoryid.length == 0) {
  //     getAllCategoryList(null);
  //     setUserId(localStorage.getItem("userId"));
  //     let id = localStorage.getItem("userId");
  //   }

  // }, []);
  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <AppDrawer />
            {/* <NavigateBeforeIcon onClick={() => navigate("/")} /> */}
          </div>
          <h1>
            <marquee>Employee Task List</marquee>
          </h1>

          {localStorage.getItem("userType") == "Admin" ? (
            <div className="switchAccount profile-switcher">
              <div className="acount1" onClick={() => navigate("/ReportAdmin")}>
                <AdminPanelSettingsIcon />
              </div>
              <Switch {...label} defaultChecked />
              <div
                className="acount1 actPro"
                onClick={() => navigate("/EmployeeTaskListNew")}
              >
                <Person2Icon />
              </div>
            </div>
          ) : null}
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain reportAdminMain">
          <div className="personalTsProfileTpOut emploTsTop">
            <div
              className="personalTsProfileTp"
              //onClick={() => navigate("/Profile")}
            >
              {employeeDetails.profile_pic ? (
                <img src={employeeDetails.profile_pic} />
              ) : (
                <img src={avatarPic} />
              )}
            </div>
            <h2>{employeeDetails.full_name}</h2>
            <div className="picUplod">
              <CreateIcon />
              <input type="file" onChange={(e) => uploadImage(e)} />
            </div>
          </div>
          <p className="errorSt">{employeeImageErrMsg}</p>
          <h1 className="dayDateTimeTx">
            {moment(todaysDate.time).format("dddd")},{" "}
            {moment(todaysDate.time).format("L")},{" "}
            {moment(todaysDate.time).format("LTS")}{" "}
          </h1>
          {categoryList.length > 0 ? (
            <div
              className="tabsMainD topPadinTab forProSwicherPatch"
              style={{ paddingTop: 150 }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  {categoryList ? (
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                      className={`kkkk ${scrolling ? "scrolledTab" : ""}`}
                    >
                      {categoryList.map((catObj, i) => (
                        <Tab
                          label={catObj.category_name}
                          onClick={(e) =>
                            changeList(
                              e,
                              catObj.categoryid,
                              catObj.category_name
                            )
                          }
                        />
                      ))}
                    </Tabs>
                  ) : (
                    <></>
                  )}
                </Box>
                {categoryList.map((catObj, i) => (
                  <CustomTabPanel value={value} index={i}>
                    <div className="tabInrContDiv">
                      <div className="storOpeningMain">
                        <div className="listingSearch">
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => getSerachList(e.target.value)}
                          />
                          <div className="sortingIcoOut">
                            <ToggleButton
                              value="check"
                              selected={selected}
                              onChange={() => {
                                handleChangeSortBy();
                              }}
                            >
                              <ImportExportIcon
                                className="visitorFilterIco"
                                aria-haspopup="true"
                              />
                            </ToggleButton>
                          </div>
                        </div>

                        <Grid item xs={12} md={12}>
                          {completeCount > 0 ? (
                            <Grid item xs={12} md={12}>
                              <div className="progBarMain">
                                <div className="progBarInr">
                                  <section>
                                    {completePercentage > 0 ? (
                                      <ol class="progress-bar">
                                        {Array.from({ length: 10 }, (_, i) =>
                                          completePercentage >= 10 * (i + 1) &&
                                          completePercentage >= 10 * (i + 2) ? (
                                            <li
                                              key={i}
                                              class="is-complete"
                                            ></li>
                                          ) : completePercentage >=
                                              10 * (i + 1) &&
                                            completePercentage <=
                                              10 * (i + 2) ? (
                                            <li key={i} class="is-active">
                                              {completePercentage}%
                                            </li>
                                          ) : (
                                            <li key={i}></li>
                                          )
                                        )}
                                      </ol>
                                    ) : null}

                                    {/* <ol class="progress-bar">
                            <li class="is-complete">
                              <span>10%</span>
                            </li>
                            <li class="is-complete">
                              <span>20%</span>
                            </li>
                            <li class="is-complete">
                              <span>30%</span>
                            </li>
                            <li class="is-complete">
                              <span>40%</span>
                            </li>
                            <li class="is-complete">
                              <span>50%</span>
                            </li>
                            <li class="is-complete">
                              <span>60%</span>
                            </li>
                            <li class="is-active">
                              <span>70%</span>
                            </li>
                            <li>
                              <span>80%</span>
                            </li>
                            <li>
                              <span>90%</span>
                            </li>
                            <li>
                              <span>100%</span>
                            </li>
                          </ol> */}
                                  </section>
                                </div>
                              </div>
                            </Grid>
                          ) : null}
                        </Grid>

                        {employeeTaskList.length > 0 ? (
                          <div className="employyeListingMain progessTop">
                            {employeeTaskList.map((takObj, index) => (
                              <div className={`swipe-container`}>
                                <div
                                  className="emploTaskListInr"
                                  id="listID"
                                  onClick={forSelectList}
                                >
                                  <div className="withCheck">
                                    <div
                                      onClick={
                                        takObj.assigntask != null &&
                                        takObj.assigntask[0].empid == UserId
                                          ? (e) =>
                                              handleClick(
                                                index,
                                                takObj.taskid,
                                                UserId,
                                                takObj.assigntask[0].taskstatus
                                              )
                                          : takObj.assigntask != null &&
                                            takObj.assigntask[0].empid != UserId
                                          ? (e) =>
                                              handleskip(
                                                e,
                                                takObj.assigntask[0].full_name
                                              )
                                          : (e) =>
                                              handleClick(
                                                index,
                                                takObj.taskid,
                                                UserId,
                                                "Open"
                                              )
                                      }
                                      id={
                                        "listID-" +
                                        index +
                                        takObj.taskid +
                                        UserId
                                      }
                                      className={
                                        takObj.assigntask != null
                                          ? takObj.assigntask[0].taskstatus ==
                                            "Assigned"
                                            ? "class2"
                                            : "class1"
                                          : ""
                                      }
                                    >
                                      <img src={unlock} className="forClass1" />
                                      <VerifiedIcon className="forClass2" />
                                      <LockPersonIcon className="forClass3" />
                                    </div>
                                    <div>
                                      <h1>{takObj.task_name}</h1>
                                      <span className="inrTileTaskName">
                                        {categoryname}
                                      </span>
                                      {takObj.assigntask != null ? (
                                        <em className="byWicTx">
                                          {takObj.assigntask != null
                                            ? takObj.assigntask[0].full_name
                                            : null}
                                          ,{" "}
                                          {moment(
                                            takObj.assigntask[0].created_at
                                          ).format("LTS")}
                                        </em>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>

                                  {takObj.is_beforeafter ? (
                                    <div className="taskImUpload">
                                      <CameraAltOutlinedIcon
                                        onClick={() =>
                                          handleClickOpen(
                                            takObj.taskid,
                                            UserId,
                                            takObj
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <>
                            <div className="dataNoF">
                              <img src={noPic} />
                              <p>Data Not Found</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </CustomTabPanel>
                ))}
              </Box>
            </div>
          ) : (
            <div className="dataNoF">
              <img src={noPic} />
              <p>Data Not Found</p>
            </div>
          )}
        </div>

        {/* <div className="profileSwitchOuter"> */}
        <UserBottom />
        {/* <div className="profileSwitchMain">
            <div className="profileSwitchInr">
              <Button
                onClick={() => navigate("/EmployeeTaskListNew")}
                className="activeProSwich"
              >
                <ApartmentIcon /> Business
              </Button>
              <Button onClick={() => navigate("/PersonalTaskNew")}>
                <PermIdentityIcon /> Personal
              </Button>
            </div>
          </div> */}
        {/*  </div> */}

        {/* <FloatButton route1={"EmployeeTaskList"} /> */}
      </div>

      {/* BeforAfterImage */}
      {/* <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore ? (
                        <>
                          {ImageUploadBefore.map((img) => (
                            <div className="befAftImgDiv">
                              <img src={img} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter ? (
                        <>
                          {ImageUploadAfter.map((img) => (
                            <div className="befAftImgDiv">
                              <img src={img} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div> */}

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore.length < 4 ? (
                        <div className="befAftImgDiv newUpImMain">
                          {/* <img src={afterBef1} /> */}
                          <AddAPhotoIcon />
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesBefore(e)}
                          >
                            <input type="file" />
                            Upload Image
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadBefore.length > 0 ? (
                        <>
                          {ImageUploadBefore.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              <img src={imgObj} />
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgBefore(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageBeforeErrMsg}</p>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter.length < 4 ? (
                        <div className="befAftImgDiv newUpImMain">
                          <AddAPhotoIcon />
                          {/* <img src={afterBef2} /> */}
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesAfter(e)}
                          >
                            <input type="file" />
                            Upload Image
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadAfter.length > 0 ? (
                        <>
                          {ImageUploadAfter.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              <img src={imgObj} />
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgAfter(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageAfterErrMsg}</p>
                    </div>
                  </div>
                  <Grid item xs={12} md={12}>
                    <Button className="logginBt" onClick={updateTaskSchedular}>
                      {" "}
                      Submit
                    </Button>
                  </Grid>
                  {showLoader ? (
                    <div className="loaderNew">
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  // function NextArrow(props) {
  //   const datesArrowArrayVal = getArrowDatesArray(startDate, endDate);
  //   const selectedDate = datesArrowArrayVal[props.currentSlide];
  //   console.log("Selected date:", selectedDate);
  //   if (startADate != selectedDate) {
  //     setStartADate(selectedDate);
  //     const date = moment(selectedDate);
  //     const dayName = date.format("dddd");
  //     setWeekDay(dayName);
  //     ChangeDateFun(categoryid, selectedDate, dayName);
  //   }
  //   return (
  //     <button class="slick-arrow slick-next" onClick={props.onClick}>
  //       {" "}
  //       Next
  //     </button>
  //   );

  //   return false;
  // }
}

export default EmployeeTaskListNew;
