import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../../../screens/admin/AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Editor } from "primereact/editor";
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";

function AddPersonalTasks() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state || {};

  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [text, setText] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [isEditTask, setIsEditTask] = useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [taskname, setTaskname] = React.useState("");
  const [taskdescription, setTaskDescription] = React.useState("");
  const [taskId, setTaskId] = useState();
  const [isActive, setIsActive] = useState(true);
  const [task, setTask] = useState('');
  const [sound, setSound] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [isListening, setIsListening] = useState(false);

  const editTask = (Id) => {
    if (Id) {
      setShowLoader(true);
      makeGetRequest("personaltask/gettask/" + Id)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);

            const savedValues = {
              task_name: response.data.data[0].task_name,
              task_description: response.data.data[0].task_description,
            };
            setIsEditTask(true);
            setFormValues(savedValues);
            setText(response.data.data[0].task_description);
            setShowLoader(false);
            setTaskname(response.data.data[0].task_name);
            setTask(response.data.data[0].task_name)
            setTaskDescription(response.data.data[0].task_description);
            setIsAdmin(response.data.data[0].is_beforeafter);
            setIsActive(response.data.data[0].status);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });
    }

    /*  console.log("Call");
    setIsEditTask(true);
    if(data) {
      setFormValues(data);
      setTaskId(data.taskid);
      setText(data.task_description);
      setTaskname(data.task_name);
      setIsAdmin(data.is_beforeafter);
    } else setFormValues(null); */
  };

  const initialValues = {
    task_name: "",
    //task_description: "",
    //is_beforeafter:false
  };

  const validationSchema = Yup.object({
    //task_name: Yup.string().required("Required"),
    //task_description: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    console.log("Form Data", values);
    if (isEditTask) {
      setShowLoader(true);
      setLoginErrMsg("");
      setDescriptionError("");
     
      values.taskid = Id;
      values.status = isActive;
      values.is_beforeafter = isAdmin;
      values.is_deleted = false;
      values.task_name = taskname;
      console.log("Form Data", values);
      if (taskdescription) {
        values.task_description = taskdescription;
        await makePostRequest("personaltask/updatetask", values)
          .then((response) => {
            console.log("error with response===>" + response);
            if (response.data) {
              console.log(response);
              setLoginErrMsg("");
              setShowLoader(false);
              localStorage.setItem("TabName", "Task");
              navigate("/PersonalTasks");
              setTaskDescription("");
            }
          })
          .catch((err) => {
            console.log("Err");
            console.log(err);
            let errMsg = err.message;
            console.log(errMsg);
            setShowLoader(false);
            setLoginErrMsg(errMsg);
          });
      }
    } else {
      setShowLoader(true);
      setLoginErrMsg("");
      setDescriptionError("");
      let userid = localStorage.getItem('userId')
      values.empid = parseInt(userid)
      values.status = isActive;
      values.is_deleted = false;
      values.is_beforeafter = isAdmin;
      values.task_name = taskname;
      console.log("Form Data", values);
      if (taskdescription) {
        values.task_description = taskdescription;
        await makePostRequest("personaltask/inserttask", values)
          .then((response) => {
            console.log("error with response===>" + response);
            if (response.data) {
              console.log(response);
              setLoginErrMsg("");
              setShowLoader(false);
              setTask('');
              localStorage.setItem("TabName", "Task");
              navigate("/PersonalTasks");
              setTaskDescription("");
            }
          })
          .catch((err) => {
            console.log("Err");
            console.log(err);
            let errMsg = err.message;
            console.log(errMsg);
            setShowLoader(false);
            setLoginErrMsg(errMsg);
          });
      } else {
        setDescriptionError("Required");
      }
    }
  };

  const handleTaskName = (e) => {
    if (e.target.value != "") {
      setTaskname(e.target.value);
    }
  };

  const handleTaskDescription = (e) => {
    if (e.htmlValue != "") {
      setTaskDescription(e.htmlValue);
    }
  };
  const handleChange = (e) => {
    setIsAdmin(e.target.checked);
    const savedValues = {
      task_name: taskname,
      task_description: taskdescription,
      is_beforeafter: e.target.checked,
    };
    setFormValues(savedValues);
  };

  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  };
  useEffect(() => {
    if (Id && sound==0) editTask(Id);
    if (!('webkitSpeechRecognition' in window)) {
      alert('Your browser does not support speech recognition. Please try Chrome.');
      return;
    }
    
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setTask(transcript);
      setTaskname(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);


  const handleStartListening = () => {
    setIsListening(true);
    document.getElementById("task_name").focus();
    setTask('')
    setSound(1)
  };

  const handleAddTask = () => {
    if (task) {
      setTasks([...tasks, task]);
      setTask('');
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate("/PersonalTasks")} />
          </div>
          {isEditTask ? <h1>Edit Personal Task</h1> : <h1>Add Personal Task</h1>}
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div className="microphoneIcon">
                      
                    <FormikControl
                      control="input"
                      type="text"
                      label="Add Title"
                      placeholder="Add Title"
                      labelClass="myLable"
                      name="task_name"
                      id="task_name"
                      value={task}
                      onChange={(e)=>setTask(e.target.value)}
                      className="myInput"
                      onBlur={(e) => handleTaskName(e)}
                    />
                     <SettingsVoiceIcon className="microphone" onClick={handleStartListening} disabled={isListening}/>
                    </div>
 
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="myCheckBx">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAdmin === true}
                              name="is_beforeafter"
                              onChange={(e) => handleChange(e)}
                            />
                          }
                          label="Does it need Before and After photos?"
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <label className="myLable">Procedure</label>
                    <div className="card myTxEditor">
                      <Editor
                        value={text}
                        onTextChange={(e) => handleTaskDescription(e)}
                        name="task_description"
                        style={{ height: "320px" }}
                      />
                      <p style={{ fontSize: 12, color: "red" }}>
                        {descriptionError}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isActive}
                            onChange={(e) =>
                              checkActiveStatus(e.target.checked)
                            }
                          />
                        }
                        label="Active"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button type="submit" className="logginBt">
                      {isEditTask ? <>Edit</> : <>Add</>} Task
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <h1 className="bodyHeadignAdmin">Recently Added</h1>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="employyeListingMain">
                      <div className="emploTaskListInr">
                        <div>
                          <h1>Task Name</h1>
                        </div>
                        <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon />
                        </div>
                      </div>
                    </div>
                  </Grid> */}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <AdminBottomNav />
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddPersonalTasks;
