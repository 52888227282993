import React, {useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert"; 

function EditEmployeeAdmin() {
  const navigate = useNavigate();
  const { empId } = useParams();
  const [isAdmin, setIsAdmin] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [fullname, setFullname] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailid, setEmailid] = React.useState("");
  const [contactno, setContactno] = React.useState("");
  const [isActive, setIsActive] = useState(true);
  const [employeeCode, setEmployeeCode] = React.useState(0);
  const [reliabilityScore, setReliabilityScore] = useState();

  const initialValues = {
    employee_code: '',
    full_name:'',
    email_id:'',
    contact_no:'',
    is_admin:false,
    reliability_score: '',
    device_user_type: '',
  };

  const validationSchema = Yup.object({
    employee_code: Yup.string().max(8).required("Required"),
    full_name:Yup.string().required('Required'),
    email_id:Yup.string().email('Invalid email.'),
    contact_no: Yup.number().integer().typeError("Please enter a valid phone number").required('Required'),
    reliability_score: Yup.string().required("Required"),
    device_user_type: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    console.log("Form Data", values);
    values.employee_id = parseInt(empId);
    //setShowLoader(true);
    setLoginErrMsg("");
    values.status = isActive;
    values.is_admin = isAdmin;
    values.is_deleted = false;
    if(isAdmin)
    values.user_type = 'Admin';
    else
    values.user_type = 'User';
    values.company_id = parseInt(localStorage.getItem('companyId'));

    delete values.password;

    await makePostRequest("employee/updateemployee", values)
      .then((response) => {
        console.log("error with response===>"+response);
        if (response.data) {
          setLoginErrMsg("");
          //setShowLoader(false);
          navigate('/EmployeeListing');
        }
      })
      .catch((err) => {
        swal("Warning", err.response.data.message, "warning", {
          timer: 3000,
          buttons: false,
        });
        //setShowLoader(false);
      });
  }

  const checkAdminStatus = (status) => {
    console.log("Status", status);
    setIsAdmin(status);
  }
  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  }

  const handleEmployeeCode = (e) => {
    if (e.target.value != '') {
        setEmployeeCode(e.target.value)
    }
  };

  React.useEffect(() => {

    if (empId) 
    {
        //setShowLoader(true);
        makeGetRequest("employee/getemployee/" + empId)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0])
            setFormValues(response.data.data[0])
            const savedValues = {
                employee_code: response.data.data[0].employee_code,
                full_name:response.data.data[0].full_name,
                email_id:response.data.data[0].email_id,
                contact_no:response.data.data[0].contact_no,
                is_admin:response.data.data[0].is_admin,
                reliability_score: response.data.data[0].reliability_score,
                device_user_type: response.data.data[0].device_user_type,
            }

            /* setFullname(response.data.data[0].full_name)
            setEmailid(response.data.data[0].email_id)
            setContactno(response.data.data[0].contact_no)
             */
            setIsAdmin(response.data.data[0].is_admin)
            setIsActive(response.data.data[0].status)
            setEmployeeCode(response.data.data[0].employee_code)
            setReliabilityScore(response.data.data[0].reliability_score)
            setFormValues(savedValues)
            //setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          //setShowLoader(false);
        });  
    }
  },[])

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate("/EmployeeListing")} />
          </div>
          <h1>Edit Employee</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={ formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Id"
                      placeholder="Employee Id"
                      labelClass="myLable"
                      name="employee_code"
                      className="myInput"
                      onBlur={(e) => handleEmployeeCode(e)}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Name"
                      placeholder="Employee Name"
                      labelClass="myLable"
                      name="full_name"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Phone Number"
                      placeholder="Phone Number"
                      labelClass="myLable"
                      name="contact_no"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Email"
                      placeholder="Email"
                      labelClass="myLable"
                      name="email_id"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="select"
                      label="Select Device User Type"
                      name="device_user_type"  // Formik field name
                      options={[
                        { key: 'Select an option', value: '' },
                        { key: 'Active User', value: 'activeUser' },
                        { key: 'Test User', value: 'testUser' },
                      ]}  // Array of options
                      labelClass="myLable"
                      className="myInput"
                    />
                  </Grid>
                  {/* code by yamini*/}
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="select"  // This specifies it is a dropdown/select control
                      label="Select Relibility Score"
                      name="reliability_score"  // Formik field name
                      options={[
                        { key: 'Select an option', value: '' },
                        { key: '0.00', value: '0.00' },
                        { key: '0.10', value: '0.10' },
                        { key: '0.20', value: '0.20' },
                        { key: '0.30', value: '0.30' },
                        { key: '0.40', value: '0.40' },
                        { key: '0.50', value: '0.50' },
                        { key: '0.60', value: '0.60' },
                        { key: '0.70', value: '0.70' },
                        { key: '0.80', value: '0.80' },
                        { key: '0.90', value: '0.90' },
                        { key: '1.00', value: '1.00' }
                      ]}  // Array of options
                      labelClass="myLable"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={<Switch checked={isActive} onChange={(e)=>checkActiveStatus(e.target.checked)}/>}
                        label="Active"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={<Switch checked={isAdmin} onChange={(e) => checkAdminStatus(e.target.checked)} />}
                        label="Is this Admin User?"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button type="submit" className="logginBt">Edit Employee</Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <AdminBottomNav />
      </div>
      {showLoader ? (
        <div className="loaderNew">
        <div className="loader"></div>
      </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default EditEmployeeAdmin;
