import React from "react";
import AddIcon from "@mui/icons-material/Add";
import editIco from "../../../../assets/images/edit-icon.svg";
import companyLogo from "../../../../assets/images/avtarPic.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SuperAdminBottomTab from "../SuperAdminBottomTab";
import { makeGetRequest } from "../../../../utils/utils";
import LogoutIcon from "@mui/icons-material/Logout";

function SuperAdminDashboard() {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = React.useState(false);
  const [companyList, setCompanyList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);

  async function getAllCompanyList() {
    setShowLoader(true);
    await makeGetRequest("/superadmin/getcompanylist")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            setCompanyList(response.data.data);
          } else {
            setCompanyList([]);
          }
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  React.useEffect(() => {
    getAllCompanyList();
  }, []);

  return (
    <div className="wrapper">
      {/* TopBar */}
      <div className="innerPageTopSecBackPatch"></div>
      <div className="innerPageTopSec">
        <div className="forBackArrow">
          {/* <LogoutIcon sx={{ color: 'black', fontSize: 30 }} onClick={logoutFunction} /> */}
        </div>
        <h1>Super Admin Dashboard</h1>
        <IconButton
          className="addEmpTpBt"
          onClick={() => navigate("/AddCompanyAdmin")}
        >
          <AddIcon />
        </IconButton>
      </div>
      {/* TopBarEnd */}
      {/* <Button className='logginBt' onClick={()=>navigate('/AdminList')}>Admin List</Button> */}
      <div className="superAddminDashMain">
        <div
          className="companyDiv addCompanyDiv"
          onClick={() => navigate("/AddCompany")}
        >
          <div className="companyAddIco">
            {" "}
            <AddIcon />{" "}
          </div>
          <h1>Add Company</h1>
        </div>

        {companyList.map((option) => (
          <div className="companyDiv">
            <div className="compLogImg">
              {option.company_logo ? (
                <img src={option.company_logo} />
              ) : (
                <img src={companyLogo} />
              )}
            </div>
            <h1>{option.company_name}</h1>

            <img
              src={editIco}
              className="comEdiIco"
              onClick={() => navigate("/EditCompany/" + option.company_id)}
            />
          </div>
        ))}
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
      {/* <Button  onClick={() => navigate("/AddCompany")} className='logginBt stickyBtBottom'>Add Company</Button> */}
      <SuperAdminBottomTab />
    </div>
  );
}

export default SuperAdminDashboard;
