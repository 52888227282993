import React,{useEffect,useState} from 'react'
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate,useLocation } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import profilePic from "../../../assets/images/avtarPic.png";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";


 function AdminTasksViewPreferences() {
    const location = useLocation();
    const dataReceived = location.state;
    console.log(dataReceived);
    const navigate = useNavigate();
    const [employee,setEmployee]=React.useState([])

     async function getEmployeePreferencesForAdmin(){
        let bodyFormData={
            "search":search,
            "startDate":dataReceived.startDate,
            "endDate":dataReceived.endDate
        }
        await makePostRequest("schedular/getEmployeePreferencesForAdmin", bodyFormData)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data);
            setEmployee(response.data.data);
          }
          else
          {
            setEmployee([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const[search,setSearch]=React.useState("")
    const handleChange=(e)=>{
        setSearch(e.target.value);
    }

    useEffect(()=>{
        getEmployeePreferencesForAdmin()
    },[search])

    return (
        <div className="wrapper">
            <div className="innerPageTopSecBackPatch"></div>
            <div className="innerPageTopSec">
                <div className="forBackArrow">
                    <NavigateBeforeIcon onClick={() => navigate(-1)} />
                </div>
                <h1>Employee Preferences</h1>
                <div className="forBackArrow"></div>
            </div>
            <div className="listingSearch">
                <input
                    type="text"
                    placeholder="Search"
                    onChange={handleChange}
                />
            </div>
            <div className='preferenceTableOuter'>
                <table width="100%"  >
                    <thead>
                        <tr className='preferenceTableHeading'>
                            <th>
                            <p className='preferenceTableP'>Employee Name</p>
                            </th>
                            <th>
                                <p className='preferenceTableP'>Hours</p>
                            </th>
                            <th>
                                <p className='preferenceTableP'>Shifts</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {employee.map((emp,index)=>(
                        <tr>
                            <td>
                                <div className='preferenceEmpData'>
                                    <div className="preferenceEmpDataInner">
                                        <Zoom>
                                            <img src={emp.profile_pic!=null && emp.profile_pic!="" ? emp.profile_pic : profilePic} />
                                        </Zoom>
                                    </div>                                    
                                    <h1>{emp.full_name}</h1>
                                </div>
                            </td>
                            <td>
                                <div className='preferenceHours'>
                                    <p>{emp.no_of_hours> 0 ? emp.no_of_hours+"Hrs" : '-'}</p>
                                </div>
                            </td>
                            <td>
                            <div className='preferenceShifts'>
                                    <p>{emp.no_of_shifts> 0 ? emp.no_of_shifts+"Shifts" : '-'} </p>
                                </div>
                            </td>
                        </tr>
                      ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdminTasksViewPreferences;