import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Box, Button } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "react-medium-image-zoom/dist/styles.css";
import EastIcon from "@mui/icons-material/East";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import Slider from "react-slick";
import IconButton from "@mui/material/IconButton";
import { TimeRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import CloseIcon from "@mui/icons-material/Close";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";
// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import edit from "../../../assets/images/edit2.svg";
import deleteIm from "../../../assets/images/Delete.svg";
import users from "../../../assets/images/Users.svg";
import { weekdays } from "moment/moment";
import { toast } from "react-toastify";
import { parse } from "date-fns";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import acordinDeleteIm from "../../../assets/images/Delete.svg";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function CreateNewSchedule() {
  const navigate = useNavigate();
  const location = useLocation();

  const [empCount, setEmpCount] = React.useState(1); // Initialize employee count

  const handleTimeAdd = (time, locobj, index) => {
    {
      const newShifts = [...(shiftState[locobj.location] || [])];
      newShifts[index].shiftTime = time;
      setShiftState({
        ...shiftState,
        [locobj.location]: newShifts,
      });
    }
  };

  // Function to handle changes in the TimeRangePicker
  const handleTimeChange = (newTimeRange) => {
    const [newStartTime, newEndTime] = newTimeRange;
    setStartTime(newStartTime);
    setEndTime(newEndTime);

    // Update the shift timing in editshift
    SetEditShift((prevShift) => ({
      ...prevShift,
      shift_timing: `${formatTime(newStartTime)}-${formatTime(newEndTime)}`,
    }));
  };

  // Function to increment employee count
  const incrementCount2 = () => {
    SetEditShift((prevShift) => ({
      ...prevShift,
      emp_count: prevShift.emp_count + 1, // Increment emp_count
    }));
  };

  // Function to decrement employee count
  const decrementCount2 = () => {
    SetEditShift((prevShift) => ({
      ...prevShift,
      emp_count: prevShift.emp_count > 1 ? prevShift.emp_count - 1 : 1, // Decrement emp_count but ensure it stays above 1
    }));
  };

  const updateShift = async (editshift) => {
    const formattedShiftTime =
      editshift.shift_timing || `${startTimeedit}-${endTimeedit}`;

    // Make sure emp_count is updated and passed
    const empCount = editshift.emp_count;

    let bodyFormData = {
      sch_id: editschid,
      Key: editshift.Key,
      Shifttime: formattedShiftTime,
      EmpCount: empCount,
    };

    // Make API request to update the shift
    await makePostRequest(
      "schedular/UpdateAdminSchedularByIdandKey",
      bodyFormData
    )
      .then((response) => {
        console.log("Shift updated successfully:", response.data);
        toast.success("Update Done Successfully!", {
          position: "top-center", // Set the position here
          autoClose: 1500,
        });
        handleClose();
        locationList(selectedDay); // Refresh the list after update
      })
      .catch((error) => {
        console.error("Error updating shift:", error);
      });
  };

  // Define a function to parse time string
  function parseTimeString(timeStr) {
    return parse(timeStr, "hh:mm aa", new Date());
  }

  // For Modal
  const [open, setOpen] = React.useState(false);
  const [editshift, SetEditShift] = React.useState({});
  const [editschid, SetEditSchid] = React.useState({});
  const [editloc, SetEditLoc] = React.useState("");
  const [startTimeedit, setStartTime] = React.useState();
  const [endTimeedit, setEndTime] = React.useState();

  const handleClickOpen = (shiftsobj, locobj) => {
    SetEditShift(shiftsobj);
    SetEditSchid(locobj.sch_id);
    SetEditLoc(locobj.location);
    console.log("edit>>", shiftsobj);
    console.log("edit>>", locobj.location);

    const shiftTiming = shiftsobj.shift_timing; // e.g., "6:00 PM-8:00 PM"
    const [startTimeStr, endTimeStr] = shiftTiming
      ? shiftTiming.split("-")
      : [null, null];

    // Parse the start and end times
    const startTime = startTimeStr
      ? parseTimeString(startTimeStr.trim())
      : null;
    const endTime = endTimeStr ? parseTimeString(endTimeStr.trim()) : null;

    setStartTime(startTime);
    setEndTime(endTime);

    setOpen(true);
  };

  // const handleClickOpen = (shiftsobj,locobj) => {
  //   SetEditShift(shiftsobj);
  //   SetEditSchid(locobj.sch_id);
  //   console.log("edit>>",shiftsobj);
  //   console.log("edit>>",locobj);
  //   const shiftTiming = shiftsobj.shift_timing; // e.g., "6:00 PM-8:00 PM"
  //   console.log(shiftTiming);
  //   const [startTimeStr, endTimeStr] = shiftTiming ? shiftTiming.split('-') : [null, null];

  //   // Parse the start and end times
  //    const startTime = startTimeStr ? parseTimeString(startTimeStr.trim()) : null;
  //    const endTime = endTimeStr ? parseTimeString(endTimeStr.trim()) : null;

  //    setStartTime(startTime);
  //    setEndTime(endTime);

  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  const [locname, setLocationName] = useState("");
  const [templatename, setTemplateName] = useState(
    localStorage.getItem("templatename") || ""
  );
  const [loc, setLoc] = useState([]);
  // const [expanded, setExpanded] = useState(false);
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [shiftState, setShiftState] = useState({});
  const [hoursOfOperation, setHoursOfOperation] = useState({
    start: "09:00AM",
    end: "10:00PM",
  });
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(false); // Default checked

  const dayMapping = {
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    Th: "Thursday",
    F: "Friday",
    S: "Saturday",
    Su: "Sunday",
    All: "All", // Assuming 'All' selects all days
  };

  const [selectedDays, setSelectedDays] = useState({
    All: false,
    M: true,
    T: false,
    W: false,
    Th: false,
    F: false,
    S: false,
    Su: false,
  });

  const toggleDaySelection = (day) => {
    setSelectedDays((prev) => {
      // If "All" is clicked and it's currently active, reset all days to false
      if (day === "All" && prev.All) {
        return {
          All: false,
          M: false,
          T: false,
          W: false,
          Th: false,
          F: false,
          S: false,
          Su: false,
        };
      }

      // If "All" is clicked and it's not currently active, activate all days
      if (day === "All") {
        return {
          All: true,
          M: true,
          T: true,
          W: true,
          Th: true,
          F: true,
          S: true,
          Su: true,
        };
      }

      // For other days, toggle the selected day and deselect "All" if any individual day is selected/deselected
      return {
        ...prev,
        All: false,
        [day]: !prev[day],
      };
    });
  };

  const handleSwitchChange = async (event) => {
    setIsDefaultTemplate(event.target.checked); // Update state based on switch value
    var bodyFormData = {
      template_name: templatename,
      is_active: event.target.checked,
    };

    await makePostRequest(
      "schedular/ChangeAdminScheduleTemplate",
      bodyFormData
    ).then((response) => {
      if (response.data) {
        locationList(selectedDay);
      }
    });
  };

  async function addLocation() {
    let companyid = localStorage.getItem("companyId");
    let loc_name = locname;
    var bodyFormData = {
      company_id: parseInt(companyid),
      loc_name: loc_name,
    };

    await makePostRequest("location/insertLocation", bodyFormData)
      .then((response) => {
        if (response.data) {
          locationList(selectedDay);
          setLocationName("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // Format time functions
  const formatTime = (date) => {
    if (!date) return "";
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  const formatShiftTime = (shiftTime) => {
    if (shiftTime && shiftTime.length === 2) {
      return `${formatTime(shiftTime[0])}-${formatTime(shiftTime[1])}`;
    }
    return "";
  };

  const handleDayChange = (day) => {
    setSelectedDay(day);
    reloadLocationListing(day);
  };

  const reloadLocationListing = (day) => {
    locationList(day);
  };

  async function locationList(day) {
    let bodyFormData = {
      weekday: day,
      template_name: localStorage.getItem("templatename"),
    };
    await makePostRequest(
      "schedular/GetAdminInitialLocationShiftsSchedule/",
      bodyFormData
    )
      .then((response) => {
        if (response.data.data) {
          setLoc(response.data.data);

          // Initialize shiftState with one default shift per location
          const initialShiftState = {};
          response.data.data.forEach((locobj) => {
            initialShiftState[locobj.location] = [
              { id: Date.now(), empCount: 1, shiftTime: [null, null] },
            ];
          });
          setShiftState(initialShiftState);
        } else {
          setLoc([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Add a new shift block for a specific location
  const addMoreShift = (locId) => {
    setShiftState({
      ...shiftState,
      [locId]: [
        ...(shiftState[locId] || []),
        { id: Date.now(), empCount: 1, shiftTime: [null, null] },
      ],
    });
  };

  // Function to check if all shiftTime arrays contain only [null, null]
  const isShiftTimeNull = () => {
    return Object.values(shiftState).every((shifts) =>
      shifts.every(
        (shift) => shift.shiftTime[0] === null && shift.shiftTime[1] === null
      )
    );
  };

  // Disabled condition based on isShiftTimeNull function
  const isDisabled = isShiftTimeNull();

  const deleteShift = async (sch_id, Key) => {
    setShowLoader(true);
    console.log(sch_id, Key);
    swal({
      title: "Are you sure?",
      text: "You want to delete this shift.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            sch_id: sch_id,
            key: Key,
          };
          const response = await makePostRequest(
            "schedular/DeleteAdminSchedularByIdandKey",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            locationList(selectedDay);
          }
        } catch (error) {
          console.error(`Error deleting shifts for ${selectedDay}:`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  const deleteTemplateLocation = async (loc_name) => {
    setShowLoader(true);
    console.log(loc_name);
    swal({
      title: "Are you sure?",
      text: "You want to delete this location for the template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            template_name: templatename,
            new_location: loc_name,
          };
          const response = await makePostRequest(
            "schedular/deleteTemplateLocation",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            locationList(selectedDay);
          }
        } catch (error) {
          console.error(`Error deleting location for template`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  const [showLoader, setShowLoader] = React.useState(false);
  const saveShift = async (locId, loc_name) => {
    setShowLoader(true);
    // Get all selected days or handle "All" case
    const activeDays = selectedDays.All
      ? ["M", "T", "W", "Th", "F", "S", "Su"] // All days initials
      : Object.keys(selectedDays).filter((day) => selectedDays[day]);

    // Convert initials to full day names using dayMapping
    const fullDayNames = activeDays.map((initial) => dayMapping[initial]);

    let isresponse = 0;
    // Loop through each selected day
    for (const day of fullDayNames) {
      const formattedShifts = (shiftState[locId] || []).map((shift) => ({
        Key: shift.id,
        Day: day, // Use the full day name
        Shifttime: formatShiftTime(shift.shiftTime),
        Location: loc_name,
        EmpCount: shift.empCount,
      }));

      // Find existing schedule for this location
      let res = loc.filter((l) => l.location === loc_name);
      let sch_id = null;
      if (res.length > 0) {
        sch_id = res[0].sch_id;
      }

      // Prepare the request body
      let bodyFormData = {
        weekday: day, // Set the current day from the loop
        sch_id: sch_id,
        is_active: isDefaultTemplate,
        working_operation: `${hoursOfOperation.start}-${hoursOfOperation.end}`,
        shifts: formattedShifts,
        template_name: templatename,
      };

      // Log the data for debugging
      console.log(`Saving shifts for ${day}:`, bodyFormData);

      // Make the API request for each day
      try {
        const response = await makePostRequest(
          "schedular/insertadminschedular",
          bodyFormData
        );
        if (response.data.data) {
          // Reload location list or handle success

          isresponse = 1;
          locationList(day);
          setShowLoader(false);
        } else {
          isresponse = 0;
          setLoc([]);
        }
      } catch (error) {
        isresponse = 0;
        console.error(`Error saving shifts for ${day}:`, error);
      }
    }
    if (isresponse == 1) {
      toast.success("Shift time is set successfully!", {
        position: "top-center", // Set the position here
        autoClose: 1500,
      });
    }
  };

  const incrementCount = (locId, index) => {
    const newShifts = [...(shiftState[locId] || [])];
    newShifts[index].empCount += 1;
    setShiftState({
      ...shiftState,
      [locId]: newShifts,
    });
  };

  const decrementCount = (locId, index) => {
    const newShifts = [...(shiftState[locId] || [])];
    if (newShifts[index].empCount > 0) {
      newShifts[index].empCount -= 1;
    }
    setShiftState({
      ...shiftState,
      [locId]: newShifts,
    });
  };

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  useEffect(() => {
    localStorage.setItem("TabName", "Schedule");
    const { data } = location.state || {};
    if (data) setIsDefaultTemplate(data.is_active);

    console.log(">>schlist", data);
    locationList(selectedDay);
  }, []);

  const handleDayChangeFromSlider = (nextIndex) => {
    const daysShort = ["M", "T", "W", "Th", "F", "S", "Su"];
    const selectedDay = daysShort[nextIndex];

    // Reset all days to false, then set the selected day to true
    setSelectedDays((prevState) => ({
      ...Object.keys(prevState).reduce(
        (acc, key) => ({ ...acc, [key]: false }),
        {}
      ),
      [selectedDay]: true,
    }));

    // Optionally, if you need to handle day-specific functionality:
    handleDayChange(dayMapping[selectedDay]); // if handleDayChange is used for reloading location listing
  };

  const settings = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 7,
    speed: 500,
    beforeChange: (current, next) => handleDayChangeFromSlider(next),
  };

  const [expanded, setExpanded] = useState(null);

  // Set the first location as expanded by default when loc is updated
  useEffect(() => {
    if (loc.length > 0) {
      setExpanded(loc[0].location);
    }
  }, [loc]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      <div className="wrapper">
        <div className="forUpperBottomBt">
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow">
              <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
            </div>
            <h1>
              {" "}
              <marquee>Create New Weekly Schedule Template</marquee>
            </h1>
            <div className="forBackArrow"></div>
          </div>

          <div className="addAdminEmployeeMain">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <p className="hrsOperation">
                  Hours of Operation <b>{hoursOfOperation.start}</b>
                  <ArrowForwardIcon />
                  <b>{hoursOfOperation.end}</b>
                </p>
              </Grid>
              <Grid item xs={12} md={12}>
                <label className="myLable">Enter Shift Schedule Name</label>
                <input
                  input="text"
                  className="myInput"
                  placeholder="Enter Shift Schedule Name"
                  value={templatename}
                  onChange={(e) => setTemplateName(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value != "")
                      localStorage.setItem("templatename", e.target.value);
                    else swal("Shift Schedule Name is Mandatory");
                  }}
                  required
                />
                {templatename == "" && (
                  <div>
                    <p className="errorSt">Please Enter Template Name</p>
                  </div>
                )}
              </Grid>
              {templatename !== "" ? (
                <>
                  <Grid item xs={12} md={12}>
                    <div className="schedulAcordianTpRight">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isDefaultTemplate} // Bind state to the switch
                            onChange={handleSwitchChange} // Handle change
                          />
                        }
                        label="Apply this as default template"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="withPlsBt">
                      <label className="myLable">Add New Locations</label>
                      <div className="withPlsBtInr">
                        <input
                          type="text"
                          className="myInput"
                          placeholder="Enter Location Name"
                          value={locname}
                          onChange={(e) => setLocationName(e.target.value)}
                        />
                        <Button
                          className="plsB"
                          disabled={locname == "" ? true : false}
                          onClick={addLocation}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                      {locname == "" && (
                        <p className="errorSt">Please Enter Location</p>
                      )}
                    </div>
                  </Grid>
                  {loc.length > 0 && (
                    <Grid item xs={12} md={12}>
                      <div className="mySlik createScheduSlik widthFixedSlide">
                        <div className="slider-container">
                          <Slider {...settings}>
                            {[
                              "Mon",
                              "Tue",
                              "Wed",
                              "Thu",
                              "Fri",
                              "Sat",
                              "Sun",
                            ].map((day) => (
                              <div key={day}>
                                <h3>{day}</h3>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    {loc.length > 0 && (
                      <div className="schedularAccordianMain">
                        {loc.map((locobj, index) => (
                          <Accordion
                            key={locobj.location}
                            expanded={expanded === locobj.location}
                            onChange={handleChange(locobj.location)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`${locobj.location}-content`}
                              id={`${locobj.location}-header`}
                            >
                              <Typography>
                                <div className="accordianDelete">
                                  <img
                                    src={acordinDeleteIm}
                                    onClick={() =>
                                      deleteTemplateLocation(locobj.location)
                                    }
                                  />
                                  {locobj.location}{" "}
                                  <span>{locobj.shift_count} Shifts</span>
                                </div>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div className="afterAddShiftDiv">
                                  {locobj.shifts.length > 0 &&
                                    locobj.shifts.map((shiftsobj, index) => (
                                      <div
                                        key={shiftsobj.shift_timing}
                                        className="afterAddShiftInr"
                                      >
                                        <em>Shift{index + 1}</em>
                                        <p>{shiftsobj.shift_timing}</p>
                                        <span>{shiftsobj.shift_hours}Hr</span>
                                        <div className="actionAftrSc">
                                          <IconButton
                                            aria-label="edit"
                                            className="usrsB"
                                          >
                                            <img src={users} />{" "}
                                            {shiftsobj.emp_count}
                                          </IconButton>
                                          <IconButton
                                            aria-label="edit"
                                            onClick={() =>
                                              handleClickOpen(shiftsobj, locobj)
                                            }
                                          >
                                            <img src={edit} />
                                          </IconButton>
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                              deleteShift(
                                                locobj.sch_id,
                                                shiftsobj.Key
                                              )
                                            }
                                          >
                                            <img src={deleteIm} />
                                          </IconButton>
                                        </div>
                                      </div>
                                    ))}
                                </div>

                                <details className="expandTx">
                                  <summary>Add New Shift</summary>
                                 
                                    <div className="scheduCon">
                                      {(shiftState[locobj.location] || []).map(
                                        (shift, index) => (
                                          <div
                                            key={shift.id}
                                            className="timingAddDiv"
                                          >
                                            <div className="timingAddLeft">
                                              <label>Shift Hours</label>
                                              <TimeRangePicker
                                                format="hh:mm aa"
                                                showMeridiem
                                                value={shift.shiftTime}
                                                onChange={(time) => {
                                                  const newShifts = [
                                                    ...(shiftState[
                                                      locobj.location
                                                    ] || []),
                                                  ];
                                                  newShifts[index].shiftTime =
                                                    time;
                                                  setShiftState({
                                                    ...shiftState,
                                                    [locobj.location]:
                                                      newShifts,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="timingAddLeft">
                                              <label>Emp Per Shift</label>
                                              <div className="countD">
                                                <button
                                                  onClick={() =>
                                                    decrementCount(
                                                      locobj.location,
                                                      index
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <div>{shift.empCount}</div>
                                                <button
                                                  onClick={() =>
                                                    incrementCount(
                                                      locobj.location,
                                                      index
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                      <div className="setDayMain">
                                        <div
                                          className={`dayInD ${
                                            selectedDays.All ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("All")
                                          }
                                        >
                                          All
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.M ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("M")
                                          }
                                        >
                                          M
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.T ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("T")
                                          }
                                        >
                                          T
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.W ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("W")
                                          }
                                        >
                                          W
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.Th ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("Th")
                                          }
                                        >
                                          T
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.F ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("F")
                                          }
                                        >
                                          F
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.S ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("S")
                                          }
                                        >
                                          S
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.Su ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("Su")
                                          }
                                        >
                                          Su
                                        </div>{" "}
                                      </div>
                                      <div className="twoBtLi">
                                        <Button
                                          className="addMorScheTimBt saveShiftB"
                                          onClick={() =>
                                            saveShift(
                                              locobj.location,
                                              locobj.location
                                            )
                                          }
                                          disabled={isDisabled}
                                        >
                                          Save Shifts
                                        </Button>
                                        <Button
                                          className="addMorScheTimBt addMorBt"
                                          onClick={() =>
                                            addMoreShift(locobj.location)
                                          }
                                        >
                                          Add More
                                        </Button>
                                      </div>
                                    </div>
                                  
                                </details>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                <p></p>
              )}
            </Grid>
          </div>
        </div>
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Edit Shift</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="schecdulEditMain">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Shift Hours </label>
                        <TimeRangePicker
                          format="hh:mm aa"
                          showMeridiem
                          value={[startTimeedit, endTimeedit]}
                          onChange={handleTimeChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Emp Per Shift</label>
                        <div className="countD newConInModal">
                          <button onClick={decrementCount2}>-</button>
                          <div>{editshift.emp_count}</div>
                          <button onClick={incrementCount2}>+</button>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className="modBt"
                          onClick={() => updateShift(editshift)}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
    </>
  );
}

export default CreateNewSchedule;
