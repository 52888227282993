import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import "react-medium-image-zoom/dist/styles.css";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";

import pencil from "../../../assets/images/pencil.svg";
import availabilityPreview from "../../../assets/images/availabilityPreview.svg";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import swal from "sweetalert";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteIcon from "@mui/icons-material/Delete";
const label = { inputProps: { "aria-label": "Switch demo" } };

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ArchivePage() {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tabValues, setTabValues] = useState({}); // To track active tab for each template

  const handleChangeTab = (templateName, newValue) => {
    setTabValues((prevTabValues) => ({
      ...prevTabValues,
      [templateName]: newValue,
    }));
  };
  const initialDayData = {
    shifts: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
    emp: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
  };

  const [dayData, setDayData] = useState(initialDayData);

  async function getlocationwiseSummarycount(loc_name, template_name) {
    var bodyFormData = {
      location: loc_name,
      templatename: template_name,
    };

    await makePostRequest(
      "schedular/GetShiftAndEmpCountByLocation",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);

          const updatedDayData = {
            shifts: { ...initialDayData.shifts },
            emp: { ...initialDayData.emp },
          };

          // Update only the weekdays present in the API response
          response.data.data.forEach((item) => {
            updatedDayData.shifts[item.weekday] = item.count;
            updatedDayData.emp[item.weekday] = item.sum;
          });

          // Update dayData for the specific template
          setTemplateDayData((prevTemplateDayData) => ({
            ...prevTemplateDayData,
            [template_name]: updatedDayData,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [templateDayData, setTemplateDayData] = useState({}); // To track dayData for each template

  const [showLoader, setShowLoader] = React.useState(false);
  const [archivelist, setArchiveList] = React.useState([]);
  async function getAllArchiveList() {
    await makeGetRequest(
      "schedular/getTemplateListForSummaryCount/" +
        parseInt(localStorage.getItem("companyId")) +
        "/true"
    )
      .then(async (response) => {
        if (response.data.data) {
          console.log("schedule", response.data.data);
          if (response.data.data.length > 0) {
            setArchiveList(response.data.data);
            for (const schlist of response.data.data) {
              await getlocationwiseSummarycount("All", schlist.template_name);
            }
          } else setArchiveList([]);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }


  const deleteTemplate = async (template_name) => {
    setShowLoader(true);

    swal({
      title: "Are you sure?",
      text: "You want to delete this template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            template_name,
          };
          const response = await makePostRequest(
            "schedular/deleteTemplate",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            getAllArchiveList();
          }
        } catch (error) {
          console.error(`Error in archiving:`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  const revokeTemplate = async (template_name) => {
    setShowLoader(true);

    swal({
      title: "Are you sure?",
      text: "You want to revoke this template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            template_name,
          };
          const response = await makePostRequest(
            "schedular/revokeTemplate",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            getAllArchiveList();
          }
        } catch (error) {
          console.error(`Error in archiving:`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    getAllArchiveList();
  }, []);

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Archived</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div>
          <div className="schedulAcordianMain">
            {archivelist.map((schlist, index) => (
              <div className="schedulAcordianInr">
                <div className="schedulAcordianTp">
                  <b>{schlist.template_name}</b>
                  <div className="schedulAcordianTpRight">
                    <>
                    <IconButton>
                              <DeleteIcon className="DeleteIcon" onClick={() =>
                                  deleteTemplate(schlist.template_name)
                                }/>
                    </IconButton>
                    <UnarchiveIcon
                      className="archiveIcon"
                      style={{ margin: 0 }}
                      onClick={() =>
                        revokeTemplate(schlist.template_name)
                      }
                    />
                    </>
                  </div>
                </div>
                <div className="schedulAcordianCont">
                  <div className="tabsMainD tabsMainDInr">
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={tabValues[schlist.template_name] || 0}
                          onChange={(event, newValue) =>
                            handleChangeTab(schlist.template_name, newValue)
                          }
                          aria-label="basic tabs example"
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {schlist.distinct_locations.map((loc, locIndex) => (
                            <Tab
                              key={locIndex}
                              label={loc}
                              {...a11yProps({ locIndex })}
                              onClick={() =>
                                getlocationwiseSummarycount(
                                  loc,
                                  schlist.template_name
                                )
                              }
                            />
                          ))}
                        </Tabs>
                      </Box>
                      {schlist.distinct_locations.map((loc, locIndex) => (
                        <CustomTabPanel
                          value={tabValues[schlist.template_name] || 0}
                          index={locIndex}
                          key={locIndex}
                        >
                          <div className="tabInrContDiv">
                            <div className="shiftDaysMain">
                              <div className="shiftDaysInr">
                                <div className="weeklyTaskTbl">
                                  <table width="100%">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            D<em>ays</em>
                                          </b>
                                        </td>
                                        <td>M</td>
                                        <td>T</td>
                                        <td>W</td>
                                        <td>T</td>
                                        <td>F</td>
                                        <td>S</td>
                                        <td style={{ color: "red" }}>S</td>
                                      </tr>
                                      {/* Shifts Row */}
                                      <tr>
                                        <td>
                                          <b>
                                            S<em>hifts</em>
                                          </b>
                                        </td>
                                        {weekdays.map((day) => (
                                          <td key={day}>
                                            {templateDayData[
                                              schlist.template_name
                                            ]?.shifts[day] || 0}
                                          </td>
                                        ))}
                                      </tr>

                                      {/* Employees Row */}
                                      <tr>
                                        <td>
                                          <b>
                                            E<em>mp</em>
                                          </b>
                                        </td>
                                        {weekdays.map((day) => (
                                          <td key={day}>
                                            {templateDayData[
                                              schlist.template_name
                                            ]?.emp[day] || 0}
                                          </td>
                                        ))}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CustomTabPanel>
                      ))}
                    </Box>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ArchivePage;
